import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import {
    Container,
    Row,
    Col,
    CarouselItem,
    Carousel,
    CarouselControl,
    CarouselIndicators
} from 'reactstrap';


export class HomeSlider extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = {
            activeIndex: 0,
            width: 0
        };
    }



    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth });
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.items.length ? 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    render() {
        const { activeIndex } = this.state;



        const slides = this.props.items.map((item, idx) => {
            let image;
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={idx}
                    slide={true}

                >
                    <Link to={item.Link}>
                        <img src={window.innerWidth < 768 ? Object.translate(item, 'MobileImage', this.props.lang) : window.innerWidth >= 768 && window.innerWidth < 992 ? Object.translate(item, 'TabletImage', this.props.lang) : Object.translate(item, 'Image', this.props.lang)} alt={item.title} />
                    </Link>
                    {/*<div className="content">

                        <Container>
                            <Row>
                                <Col lg="6" xs="6" className="content-col">
                                    <h6>{item.subtitle}</h6>
                                    <h3>{item.title}</h3>
                                    <p>{item.description}</p>
                                    <Link to={item.link ? item.link : ''} className="button">{this.props.translate('DETALJNIJE')}</Link>
                                </Col>
                            </Row>
                        </Container>
            </div>*/}

                </CarouselItem>
            );
        });


        return (
            <Carousel
                activeIndex={activeIndex}
                next={this.next}
                previous={this.previous}
                touch={true}
                className="home-slider carousel-fade"
                interval={2000}
            >
                <CarouselIndicators items={this.props.items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />

            </Carousel>
        )
    }
}

export default HomeSlider;