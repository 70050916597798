

import React, { Component } from 'react';
import Routes from './routes'
import socketIO from 'socket.io-client';
import popup_bg from './assets/images/popupbg.png';
import coupon from './assets/images/coupon.png';
import logo from './assets/svg/Rossen-logo-natural.svg';

import { withRouter } from 'react-router-dom';
import langs from './langs.json'
import messengerIcon from './assets/svg/messenger-icon.svg';
import Isvg from 'react-inlinesvg';

Number.prototype.formatPrice = function (currency) {
  let price = this;
  price = price * currency.exchangeRate;
  let dec_point = ',';
  let thousands_sep = '.';

  var parts = price.toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point) + ' ' + currency.code;
}

String.prototype.formatPrice = function (currency) {
  let price = parseFloat(this);
  price = price * currency.exchangeRate;
  let dec_point = ',';
  let thousands_sep = '.';

  var parts = price.toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point) + ' ' + currency.code;
}


Object.translate = function (o, s, lang) {
  if (!o) {
    return '';
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o[lang] ? o[lang] : o['de'];
}


Object.get = function (o, s) {
  //console.log(o, s)
  if (!o) {
    return null;
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.translate = this.translate.bind(this);
    this.updateBreadcrumb = this.updateBreadcrumb.bind(this);
    this.setUserData = this.setUserData.bind(this);
    this.allowCookies = this.allowCookies.bind(this);
    this.showInfoMessage = this.showInfoMessage.bind(this);
    this.hideInfoMessage = this.hideInfoMessage.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    window.googleMapsCallback = this.googleMapsCallback;
    this.setCurrency = this.setCurrency.bind(this);
    this.addToCart = this.addToCart.bind(this);


    let lang = 'de';
    let currency = {
      code: 'CHF',
      exchangeRate: 1
    }
    if (typeof localStorage !== 'undefined') {
      if (localStorage.getItem('lang')) {
        lang = localStorage.getItem('lang');
      }
    }


    this.state = {
      lang: lang,
      breadcrumb: [],
      categories: [],
      uData: null,
      cartCount: 0,
      newestProducts: [],
      popularProducts: [],
      offerProducts: [],
      news: [],
      infoMessages: {

      },
      currency: currency,
      pages: {
        'kako-naruciti': {},
        'privacy-policy': {},
        'terms-and-conditions': {}
      }
    }
  }


  addToCart(product) {

    if (window.location.pathname.indexOf('/product') == -1) {
      this.state.socketIOClient.once('addToCart', (data) => {
        console.log(data);
        if (data.successful) {
          this.showAddToCartPopUp(data);
          // this.showInfoMessage(this.translate('Artikal je uspešno dodat u korpu.'));
          this.state.socketIOClient.emit('cartInfo', {});
        } else {
          this.showInfoMessage(this.translate('Nema dovoljno na stanju.'), true);

        }
      });
    }


    this.state.socketIOClient.emit('addToCart', { _id: product._id, quantity: 1, package: product.package[0] });




  }
  showAddToCartPopUp = (data) => {

    if (this.state.addToCartPopUp) {
      this.setState({
        addToCartPopUp: null
      }, () => {
        setTimeout(() => {
          this.setState({
            addToCartPopUp: data
          })

        }, 500);
      })

      return;
    }

    this.setState({
      addToCartPopUp: data
    })
  }


  setCurrency(currency) {
    this.setState({
      currency
    })
  }

  setLang = (lang) => {
    localStorage.setItem('lang', lang);
    this.setState({
      lang: lang
    })
  }

  showInfoMessage(text, error) {
    console.log(this.state.infoMessages);
    let messages = this.state.infoMessages;
    let idx = Date.now().toString();
    messages[idx] = {
      idx: idx,
      message: text,
      error: error
    };

    this.setState({
      infoMessages: messages
    }, () => {
      this.forceUpdate();
      setTimeout(() => {
        this.hideInfoMessage(idx);
      }, 3000);
    });



  }

  hideInfoMessage(idx) {
    let messages = this.state.infoMessages;
    if (!messages[idx])
      return;
    messages[idx].animate = true;
    this.setState({
      infoMessages: messages
    }, () => {
      setTimeout(() => {


        let messages = this.state.infoMessages;
        delete messages[idx];
        this.setState({
          infoMessages: messages
        })
      }, 1000);
    })
  }

  hidePopup() {
    localStorage.popup = Math.floor(Date.now() / 1000);
    console.log(true)
    this.setState({
      popup: null
    })
  }

  allowCookies() {
    localStorage.allowCookies = true;
    this.setState({
      cookies: true
    });
  }


  updateBreadcrumb(bcrumb) {
    this.setState({
      breadcrumb: bcrumb
    });
  }

  googleMapsCallback() {
    this.setState({
      _googleMapsLoaded: true
    });
  }

  setUserData(data) {
    this.setState({
      uData: data
    });

    localStorage.uData = JSON.stringify(data);

  }

  componentDidMount() {
    document.documentElement.setAttribute('translate', 'no');

    setTimeout(() => {
      window.prerenderReady = true;
    }, 5000);

    if (localStorage.popup) {
      if (parseInt(localStorage.popup) + 24 * 60 * 60 < Math.floor(Date.now() / 1000)) {
        //alert(true);
        this.setState({
          popup: true
        })
      }
    } else {
      this.setState({
        popup: true
      })
    }

    if (localStorage.allowCookies) {
      this.setState({
        cookies: true
      });
    }

    //let socket = socketIO('https://socket.rossen-shop.ch/', { upgrade: true, transports: ['websocket'] });
    //let socket = socketIO('http://rossen-shop.ch:61003', { transports: ['websocket'] });
    let socket = socketIO('https://rossen-shop.ch:61003', { transports: ['websocket'] });
    // let socket = socketIO('http://localhost:61003');

    console.log(socket);
    this.setState({
      socketIOClient: socket
    });


    if (localStorage.uData) {
      let uData = JSON.parse(localStorage.uData);

      if (uData && uData.User && uData.User.EMail && uData.User.pk)
        socket.emit("userVerify", { email: uData.User.EMail, pk: uData.User.pk });
    }

    socket.on('connect', () => {
      this.setState({
        socketId: socket.id
      })
    })

    socket.on('reconnect', () => {
      //alert('aa')
      socket.emit('cloneConnection', { oldId: this.state.socketId });
      console.log(this.state.socketId, socket.id);

      this.setState({
        socketId: socket.id
      })
    })



    socket.on('disconnect', () => {
      //alert(true)
    })

    socket.on('cartInfo', (data) => {
      this.setState({
        cartCount: data.count
      })
    })
    socket.on('getCurrency', (data) => {
      this.setState({
        currency: {
          exchangeRate: 1,
          code: data.currency
        }
      })
    })

    socket.on('userVerify', (data) => {
      console.log(data);
      if (data.successful) {
        this.setUserData(data.user);
      }

      socket.emit('cartInfo', {});

    });


    socket.on('fetchSpecialOfferProducts', (data) => {
      this.setState({
        popularProducts: data
      });
    })
    socket.on('fetchOfferProducts', (data) => {
      this.setState({
        offerProducts: data
      });
    })


    socket.on('fetchNewestProducts', (data) => {
      this.setState({
        newestProducts: data
      });
    })




    socket.on('fetchCategories', (data) => {
      console.log(data);
      this.setState({
        categories: data
      });
    });

    socket.on('fetchNews', (data) => {
      console.log(data);
      this.setState({
        news: data
      });
    });


    socket.on('fetchPopup', (data) => {
      console.log(data);
      this.setState({
        popupData: data
      });
    });
    socket.on('fetchPages', (data) => {
      console.log(data);
      this.setState({
        pages: data
      });
    });

    socket.emit('getCurrency', {});

    socket.on('userLogout', (data) => {
      socket.emit('cartInfo', {});
    })

    socket.on('siteData', (data) => {
      console.log(data);
      this.setState({
        siteData: data
      });
    });


    // setTimeout(() => {
    //   window.fbAsyncInit = function () {
    //     window.FB.init({
    //       xfbml: true,
    //       version: 'v4.0'
    //     });
    //   };


    //   (function (d, s, id) {
    //     var js, fjs = d.getElementsByTagName(s)[0];
    //     if (d.getElementById(id)) return;
    //     js = d.createElement(s); js.id = id;
    //     js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
    //     fjs.parentNode.insertBefore(js, fjs);
    //   }(window.document, 'script', 'facebook-jssdk'));

    // }, 7000);


  }

  fetchSiteInfo = () => {
    this.state.socketIOClient.emit('fetchCategories', {});
    this.state.socketIOClient.emit('fetchNewestProducts', {});
    this.state.socketIOClient.emit('fetchSpecialOfferProducts', {});
    this.state.socketIOClient.emit('fetchOfferProducts', {});
    this.state.socketIOClient.emit('fetchPages', {});


    //this.state.socketIOClient.emit('fetchNews', {});


    this.setState({
      _siteInfoDone: true
    })
  }


  translate(text) {
    if (langs && langs[this.state.lang] && langs[this.state.lang][text]) {
      return langs[this.state.lang][text];
    } else {
      return text
    }
  }

  render() {

    return (
      <div>
        <Routes
          translate={this.translate}
          updateBreadcrumb={this.updateBreadcrumb}
          setUserData={this.setUserData}
          allowCookies={this.allowCookies}
          showInfoMessage={this.showInfoMessage}
          hideInfoMessage={this.hideInfoMessage}
          hidePopup={this.hidePopup}
          setCurrency={this.setCurrency}
          addToCart={this.addToCart}
          fetchSiteInfo={this.fetchSiteInfo}
          setLang={this.setLang}
          showAddToCartPopUp={this.showAddToCartPopUp}

          {...this.state}
        />
        <div className="pop-up-messages">
          {
            Object.values(this.state.infoMessages).map((item, idx) => {
              console.log(item);
              return (
                <div className={item.animate ? 'hide-message ' : ''} key={idx} onClick={() => this.hideInfoMessage(item.idx)}>
                  <i className="mdi mdi-close hide" />

                  <p className={item.error ? 'error' : ''}>{item.error ? <i className="mdi mdi-close" ></i> : null}{item.message}</p>
                </div>
              )
            })
          }

        </div>

        {this.state.popup && this.state.popupData ?
          <div className="popup">
            <img src={this.state.popupData.Background} className="overlay" />
            <div className="content">
              <img src={this.state.popupData.Image} className="coupon" />
              <h2>{this.state.popupData.Title}</h2>
              <h3>{this.state.popupData.Subtitle}</h3>
              <h6>{this.state.popupData.Text}</h6>
              <button className="button" onClick={() => this.hidePopup()}>{this.translate('BEGINN MIT DEM EINKAUF')}</button>
            </div>
          </div>
          : null
        }

        {!this.state.cookies ? <div className="cookies">
          <p>Wir verwenden Cookies um Ihre Erfahrung auf unserer Webseite zu verbessern. Durch die Nutzung dieser Webseite erklären Sie sich mit der Verwendung von Cookies einverstanden.</p>
          <button onClick={() => { this.allowCookies() }}>Akzeptieren</button>
          <a href='/page/Datenschutz' target="_blank" >Mehr</a>
        </div> :
          null
        }


        <div className="loader">
          <img src={logo} />
        </div>
        <a href="https://m.me/111328124675037" target="_blank">
          <div style={{ position: 'fixed', bottom: 32, right: 32, width: 60, height: 60, backgroundColor: '#fff', borderRadius: '50%', padding: 12, boxShadow: '0px 10px 10px 0px rgba(0,0,0,0.2)' }}>
            <Isvg src={messengerIcon} />
          </div>
        </a>
      </div>

    );

  }

}

export default App;
