import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Text from './fields/text';
import Textarea from './fields/textarea';

import Select from './fields/select';
import Check from './fields/check';

import {
    Col, Row, Container
} from 'reactstrap';


const required = value => value ? undefined : "Required"

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    id,
    translate,
    children,
}) => (

    <Select
        placeholder={placeholder}
        label={label}
        id={id}
        translate={translate}
        errorText={touched && error}
        error={touched && error}
        {...input}
        children={children}
    />
)



const renderTextField = ({
    input,
    placeholder,
    label,
    id,
    translate,
    type,
    meta: { touched, error },
}) => (

    <Text
        placeholder={placeholder}
        label={label}
        type={type}
        id={id}
        translate={translate}
        errorText={touched && error}
        error={touched && error}

        {...input}
    />
)
const renderTextareaField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

    <Textarea
        placeholder={placeholder}
        label={label}
        type={type}
        errorText={touched && error}
        error={touched && error}

        {...input}
    />
)
const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    translate
}) => (

    <Check
        label={label}
        translate={translate}
        {...input}
        errorText={touched && error}
        error={touched && error}

    />
)
function submitFailure(fieldList) {
    if (fieldList && Object.keys(fieldList).length) {
        for (var key in fieldList) {
            let field = key && key.toLowerCase()
            let id = `${field}-field`;
            if (field === 'address') {
                let field1 = document.getElementById(`${id}-1`);
                // let field2 = document.getElementById(`${id}-2`);
                if (field1 && !field1.value) {
                    id = `${field}-field-1`;
                } else {
                    id = `${field}-field-2`;
                }
            }
            if (field) {
                const elem = document.getElementById(id)
                if (elem) {
                    elem.focus()
                    return;
                }
            }

        }
    }

}

const countriesMapDe = ['Schweiz', 'Österreich', 'Deutschland', 'Litauen', 'Belgien', 'Luxemburg', 'Bulgarien', 'Ungarn', 'Zypern', 'Malta', 'Tschechien', 'Dänemark', 'Niederlande', 'Estland', 'Polen', 'Finnland', 'Portugal', 'Frankreich', 'Rumänien', 'Griechenland', 'Slowakei', 'Kroatien', 'Slowenien', 'Irland', 'Spanien', 'Italien ', 'Schweden', 'Lettland']
const countriesMapEn = ['Switzerland', 'Austria', 'Germany', 'Lithuania', 'Belgium', 'Luxembourg', 'Bulgaria', 'Hungary', 'Cyprus', 'Malta', 'Czech Republic', 'Denmark', ' Netherlands', 'Estonia', 'Poland', 'Finland', 'Portugal', 'France', 'Romania', 'Greece', 'Slovakia', 'Croatia', 'Slovenia', 'Ireland', 'Spain', 'Italy', 'Sweden', 'Latvia']

var orderForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>

            <Row>
                <Col lg="12">
                    <Field
                        name="Name"
                        type="text"
                        component={renderTextField}
                        label={props.translate('Ime i prezime *')}
                        placeholder=""
                        validate={required}
                        id={'name-field'}
                        translate={props.translate}

                    ></Field>

                    <Field
                        name="Street"
                        type="text"
                        component={renderTextField}
                        label={props.translate('Ulica *')}
                        placeholder=""
                        validate={required}
                        id={'street-field'}
                        translate={props.translate}
                    ></Field>

                    <Row>
                        <Col lg="6">
                            <Field
                                name="City"
                                type="text"
                                component={renderTextField}
                                label={props.translate('Grad *')}
                                placeholder=""
                                validate={required}
                                id={'city-field'}
                                translate={props.translate}
                            ></Field>
                        </Col>
                        <Col lg="6">
                            <Field
                                name="Zipcode"
                                type="text"
                                component={renderTextField}
                                label={props.translate('Poštanski broj *')}
                                placeholder=""
                                validate={required}
                                id={'zipcode-field'}
                                translate={props.translate}

                            ></Field>
                        </Col>

                    </Row>
                    <Row>
                        <Col lg="6">
                            {
                                props.lang === 'de' ?
                                    <Field
                                        name="Country"
                                        component={renderSelectField}
                                        label={props.translate('Država *')}
                                        placeholder=""
                                        validate={required}
                                        id={'country-field'}
                                        translate={props.translate}
                                    >
                                        {
                                            countriesMapDe.map((item, idx) => {
                                                return (
                                                    <option value={item}>{item}</option>

                                                )
                                            })
                                        }
                                        {/* <option>Schweiz</option>
                                <option>Deutschland</option>
                                <option>Österreich</option> */}
                                    </Field>
                                    :
                                    <Field
                                        name="Country"
                                        component={renderSelectField}
                                        label={props.translate('Država *')}
                                        placeholder=""
                                        validate={required}
                                        id={'country-field'}
                                        translate={props.translate}
                                    >
                                        {
                                            countriesMapEn.map((item, idx) => {
                                                return (
                                                    <option value={countriesMapDe[idx]}>{item}</option>

                                                )
                                            })
                                        }
                                        {/* <option>Schweiz</option>
                            <option>Deutschland</option>
                            <option>Österreich</option> */}
                                    </Field>
                            }

                        </Col>

                        {props.Country == 'Schweiz' ?
                            <Col lg="6">
                                <Field
                                    name="Region"
                                    component={renderSelectField}
                                    label={props.translate('Regija *')}
                                    placeholder=""
                                    validate={required}
                                    id={'region-field'}
                                    translate={props.translate}
                                >
                                    <option>Zürich</option>
                                    <option>Bern / Berne</option>
                                    <option>Luzern</option>
                                    <option>Uri</option>
                                    <option>Schwyz</option>
                                    <option>Unterwalden</option>
                                    <option>Glarus</option>
                                    <option>Zug</option>
                                    <option>Freiburg / Fribourg</option>
                                    <option>Solothurn</option>
                                    <option>Basel</option>
                                    <option>Schaffhausen</option>
                                    <option>Appenzell</option>
                                    <option>Sankt Gallen</option>
                                    <option>Graubünden</option>
                                    <option>Aargau</option>
                                    <option>Thurgau</option>
                                    <option>Ticino</option>
                                    <option>Vaud</option>
                                    <option>Valais / Wallis</option>
                                    <option>Neuchâtel</option>
                                    <option>Genève</option>
                                    <option>Jura</option>
                                </Field>
                            </Col>
                            :
                            null
                        }
                    </Row>
                    <Field
                        name="Phone"
                        type="text"
                        component={renderTextField}
                        label={props.translate('Telefon *')}
                        placeholder=""
                        validate={required}
                        id={'phone-field'}
                        translate={props.translate}

                    ></Field>
                    <Field
                        name="EMail"
                        type="email"
                        component={renderTextField}
                        label={props.translate('Email *')}
                        placeholder=""
                        validate={required}
                        id={'email-field'}
                        translate={props.translate}
                    ></Field>
                    <Field

                        name="Terms"
                        type="checkbox"
                        component={renderCheckField}
                        label={<>{props.translate('Pročitao sam i prihvatam')} <Link to='/page/terms-and-conditions' className="text-primary text-bold">{props.translate('Uslove i odredbe')}</Link> </>}
                        // label={<>{props.translate('Pročitao sam i prihvatam')} <a href='https://sr.rossen.shop/page/terms-and-conditions' target="_blank" className="text-primary text-bold">{props.translate('Uslove i odredbe')}</a> </>}
                        validate={[required]}
                    ></Field>

                </Col>
                {/*<Col lg="6">
                    <Field
                        name="Node"
                        type="text"
                        component={renderTextareaField}
                        label={props.translate('Napomena')}
                        placeholder=""
                    ></Field>

    </Col>*/}

            </Row>

            <p>{props.translate('* Obavezna polja')}</p>

            <button type="submit" className="button">{props.translate('NARUČI')}</button>


        </form>

    )
}

orderForm = reduxForm({
    form: 'orderForm',  // a unique identifier for this form
    onSubmitFail: (fieldList) => submitFailure(fieldList)

})(orderForm)

const selector = formValueSelector('orderForm');

orderForm = connect(state => {
    return {
        Country: selector(state, 'Country'),
    }


}, {
    ChangeRegion: value => change("orderForm", "Region", value),

})(orderForm)

export default orderForm;