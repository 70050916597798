import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import HomeHeader from './header/homeHeader';

import Snowfall from 'react-snowfall'
import Article from '../components/article'



const month = new Date().getMonth();
const day = new Date().getDate();



export const DefaultLayout = (Wrapped) => (props) => {


    return (

        <div onTouchEnd={props.touchEnd} onTouchMove={props.touchMove}>

            <HomeHeader {...props} />
            <Wrapped {...props} />
            {
                props.addToCartPopUp ?
                    <div className='add-to-cart-popup-wrap' onClick={() => props.showAddToCartPopUp(null)}>
                        <div className='add-to-cart-popup'>
                            <h3>{props.translate('Artikal je uspešno dodat u korpu.')}</h3>
                            <div className='buttons'>

                                <button onClick={() => props.showAddToCartPopUp(null)}>{props.translate('Nastavi kupovati')}</button>
                                <Link to='/cart'><button onClick={() => props.showAddToCartPopUp(null)}>{props.translate('Idi u korpu')}</button></Link>
                            </div>
                            {
                                props.addToCartPopUp.products ?
                                    <>
                                        <p>{props.translate('Kupci su takodje kupovali')}</p>
                                        <div className='similar-articles'>
                                            {props.addToCartPopUp.products.map((item) => {
                                                console.log('item',item)
                                                return <Article addToCart={() => props.addToCart(item)} lang={props.lang}
                                                    _id={item._id}
                                                    slug={item.slug}
                                                    alias={item.Alias}
                                                    image={Object.translate(item, 'Images', props.lang) ? Object.translate(item, 'Images', props.lang)[0] : null}
                                                    title={item.Name}
                                                    package={item.package} showDiscount={item.ShowDiscountPrice}
                                                    currency={props.currency}
                                                    discount={item.discount}
                                                    price={item.price ? item.price : 0}
                                                    inStock={item.StockLevel > 0 ? true : false}
                                                    translate={props.translate}>
                                                </Article>

                                            })}
                                        </div>
                                    </>
                                    :
                                    null
                            }

                        </div>
                    </div>

                    :
                    null
            }
           {(day > 5 && month == 11) || (day < 15 && month == 0) ?

                <div className="snowfall" style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, pointerEvents: 'none', zIndex: 9999 }}>
                    <Snowfall snowflakeCount={window.innerWidth < 768 ? 100 : 200}></Snowfall>
                </div>
                :
                null
            }


        </div>
    );
};

export default DefaultLayout;