import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';
import ReactPaginate from 'react-paginate';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import ListArticle from '../components/listArticle';

import Categories from '../components/categories';

import grid_icon_active from '../assets/svg/grid-view-active.svg';
import list_icon from '../assets/svg/list-view.svg';

import grid_icon from '../assets/svg/grid-view.svg';
import list_icon_active from '../assets/svg/list-view-active.svg';
import Slider from 'react-slick';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';


class CategoryPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.fetchProducts = this.fetchProducts.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.sortArticles = this.sortArticles.bind(this);

        this.state = {
            display: 'grid',
            breadcrumb: [],
            articles: [],
            total: 0,
            page: 0,
            sort: props.translate('Relevantnosti'),
            recentArticles: [
            ]
        };

    }


    componentDidMount() {
        if (this.props.socketIOClient && this.props.categories.length) {
            this.registerSocketIOEvents();
        }

    }

    sortArticles(sort) {
        this.setState({
            sort: sort
        }, () => {
            this.fetchProducts();
        });


    }

    fetchProducts() {
        console.log(this.state.page);
        let path = this.props[0].location.pathname;
        let brokenPath = path.split("&");
        let category = brokenPath[0].replace("/category", "").replace("/offer", "");
        let filters = {};
        brokenPath = this.props[0].location.search.replace('?', '').split('&');
        for (let i = 0; i < brokenPath.length; i++) {
            let brokenFilter = brokenPath[i].split("=");
            filters[brokenFilter[0]] = brokenFilter[1];
        }

        let sort = 0;
        if (this.state.sort == 'Relevantnosti') {
            sort = 0;
        } else if (this.state.sort == 'Imenu') {
            sort = 1;
        } else if (this.state.sort == 'Cijena: Opadajuće') {
            sort = 2;
        } else if (this.state.sort == 'Cijena: Rastuće') {
            sort = 3;
        }

        this.props.socketIOClient.emit('fetchProducts', { category: category, skip: filters.page ? (parseInt(filters.page) - 1) * 12 : 0, search: filters.search ? filters.search : null, brand: filters.brand ? filters.brand : null, count: 12, sort: sort, offerProducts: this.props[0].location.pathname.indexOf('/offer') !== -1 });


        if (!filters.page) {
            this.setState({
                page: 0
            }, () => {
                this.forceUpdate();
            })
        }

    }

    updateFilter(name, val) {
        let path = this.props[0].location.search;
        let brokenPath = path.replace('?', '').split("&");

        let filters = {};

        for (let i = 1; i < brokenPath.length; i++) {
            let brokenFilter = brokenPath[i].split("=");
            filters[brokenFilter[0]] = brokenFilter[1];
        }

        filters[name] = val;

        let link = this.props[0].location.pathname;

        if (link == "/category") {
            link = "/category/";
        }
        if (link == "/offer") {
            link = "/offer/";
        }

        link += '?';

        let params = [];

        for (var key in filters) {
            if (filters.hasOwnProperty(key)) {
                params.push(key + "=" + filters[key]);
            }
        }

        link += params.join('&');

        if (!filters.page) {
            this.setState({
                page: parseInt(filters.page) - 1
            }, () => {
                this.forceUpdate();
            })
        }


        this.props[0].history.push(link)

    }

    componentDidUpdate(prevProps) {


        if (this.props.socketIOClient && this.props.categories.length) {
            this.registerSocketIOEvents();
        }

        //console.log(this.props[0]);
        if (prevProps[0].location.pathname !== this.props[0].location.pathname || prevProps[0].location.search != this.props[0].location.search) {

            this.fetchProducts();
        }

    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });



        this.props.socketIOClient.on('fetchProducts', (data) => {
            console.log(data);

            this.setState({
                articles: data.products,
                total: data.total,
                brandName: data.brandName ? data.brandName : null
            });
        });

        this.fetchProducts();
    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("fetchCategories");
    }

    render() {

        return (
            <div className="category-wrap">
                <Container>
                    <Row>
                        <Col lg="12" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12" className="category-title">
                                        {this.props[0].location.pathname.indexOf('/offer') == -1 ?
                                            <h2>{this.props.translate('IZABERITE KATEGORIJU')}</h2>
                                            :
                                            <h2>{this.props.translate('AKCIJE')}</h2>

                                        }
                                    </Col>

                                    {this.props[0].location.pathname.indexOf('/offer') == -1 ?
                                        <Col lg="12" className="categories-list">
                                            <ul>
                                                <li className={this.props[0].location.pathname == `/category` ? 'active' : null}><Link to={`/category`}>{this.props.translate('Sve')}</Link> </li>
                                                {
                                                    this.props.categories.map((item, idx) => {


                                                        // if (idx < 4)
                                                        return (
                                                            <li className={this.props[0].location.pathname == `/category${item.Breadcrumb}` ? 'active' : null} key={idx}><Link to={`/category${item.Breadcrumb}`}>{Object.translate(item, 'Name', this.props.lang)}</Link>
                                                                {
                                                                    item.subcategories && item.subcategories.length ?
                                                                        <div className='categories-dropdown'>

                                                                            {
                                                                                item.subcategories.map((subcategory, sidx) => {
                                                                                    return (
                                                                                        <Link to={`/category${subcategory.Breadcrumb}`}>{Object.translate(subcategory, 'Name', this.props.lang)}</Link>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </li>

                                                        )
                                                    })
                                                }
                                            </ul>
                                        </Col>
                                        :
                                        null
                                    }

                                    {
                                        this.state.articles.map((item, idx) => {
                                            return (
                                                <Col lg="3" sm="6" xs="6" className="article-container">
                                                    <Article addToCart={() => this.props.addToCart(item)} lang={this.props.lang}
                                                        slug={item.slug}
                                                        _id={item._id}
                                                        alias={item.Alias}
                                                        discount={item.discount}
                                                        showDiscount={item.ShowDiscountPrice}
                                                        image={Object.translate(item, 'Images', this.props.lang) ? Object.translate(item, 'Images', this.props.lang)[0] : null}
                                                        title={item.Name}
                                                        package={item.package} showDiscount={item.ShowDiscountPrice}
                                                        currency={this.props.currency}
                                                        price={item.price ? item.price : 0}
                                                        inStock={item.StockLevel > 0 ? true : false}
                                                        translate={this.props.translate}>
                                                    </Article>
                                                </Col>
                                            )

                                        })
                                    }
                                    {this.state.total / 12 > 1 ?
                                        < Col lg="12">
                                            <ReactPaginate
                                                previousLabel={''}
                                                nextLabel={''}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={this.state.total / 12}
                                                marginPagesDisplayed={1}
                                                pageRangeDisplayed={2}
                                                onPageChange={(page) => { this.updateFilter('page', page.selected + 1) }}
                                                containerClassName={'pagination'}
                                                subContainerClassName={'pages pagination'}
                                                activeClassName={'active'}
                                                forcePage={this.state.page}
                                            />

                                        </Col>
                                        :
                                        null
                                    }
                                </Row>
                            </Container>

                        </Col>

                    </Row>
                </Container>

                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(CategoryPage));
