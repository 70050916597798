import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import Footer from '../containers/footer';



import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';
import wishlistIcon from '../assets/svg/wishlist-icon.svg';

import image from '../assets/images/no-image.jpg';
import Slider from "react-slick";

import bg1 from '../assets/images/bg1.png';
import bg2 from '../assets/images/bg2.png';
import bg3 from '../assets/images/bg3.png';
import detail1 from '../assets/images/detail1.png';
import detail2 from '../assets/images/detail2.png';
import detail3 from '../assets/images/detail3.png';

import YouTube, { YouTubeProps } from 'react-youtube';

import DocumentMeta from 'react-document-meta';
import cart_icon from '../assets/svg/cart-icon.svg'

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';

var striptags = require('striptags');

class DetailPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.addToWishlist = this.addToWishlist.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);

        this.state = {
            attributes: [],
            variations: [],
            product: {},

        };

    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }


    generateBreadcrumb(items, breadcrumb, level) {
        let broken = breadcrumb.split("/");
        broken.splice(0, 1);
        let arr = [];

        console.log(broken, items, breadcrumb, level)

        if (level >= broken.length) {
            return [];
        }

        for (let i = 0; i < items.length; i++) {
            if (broken[level] == items[i].Alias) {

                arr.push({
                    _id: items[i]._id,
                    name: items[i].Name,
                    link: "/category/" + (items[i].Breadcrumb === "" ? items[i].Alias : items[i].Breadcrumb + "/" + items[i].Alias)
                });

                if (items[i].subcategories && items[i].subcategories.length) {
                    arr = arr.concat(this.generateBreadcrumb(items[i].subcategories, breadcrumb, level + 1));
                }

                return arr;
            }
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

        //console.log(this.props[0]);
        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {

            this.setState({
                product: {},
            }, () => {
                // this.props.socketIOClient.emit('fetchProduct', { _id: this.props[0].match.params.id });
                this.props.socketIOClient.emit('fetchProduct', this.props[0].match.params.slug ? { slug: this.props[0].match.params.slug } : { _id: this.props[0].match.params.id });

            });
        }

        if (this.props.categories.length && this.state.product && this.state.product._id && this.state.product.Breadcrumb && !this.state._updatingBreadcrumb) {
            let breadcrumb = this.generateBreadcrumb(this.props.categories, this.state.product.Breadcrumb, 0);
            this.setState({
                _updatingBreadcrumb: true
            })
            this.props.updateBreadcrumb(!breadcrumb ? [] : breadcrumb);
        }

    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });


        this.props.socketIOClient.on('fetchProduct', (data) => {
            this.setState({
                fetchedProduct: this.props[0].match.params.id,
                product: data,
                previewImage: Object.translate(data, 'Images', this.props.lang) ? Object.translate(data, 'Images', this.props.lang)[0] : null,
                package: data.package && data.package[0],
                quantity: data.MinOrder,
                _updatingBreadcrumb: null
            }, () => {
                if (!this.props._siteInfoDone) {
                    this.props.fetchSiteInfo();
                }
                /*if (this.props.categories) {
                    console.log(this.state.product.Breadcrumb);
                    let breadcrumb = this.generateBreadcrumb(this.props.categories, this.state.product.Breadcrumb, 0);
                    this.setState({
                        _updatingBreadcrumb: true
                    })
                    this.props.updateBreadcrumb(!breadcrumb ? [] : breadcrumb);
                }*/
            });
        });


        this.props.socketIOClient.on('addToCart', (data) => {
            console.log(data);
            if (data.successful) {
                this.props.showAddToCartPopUp(data)
                // this.props.showInfoMessage(this.props.translate('Artikal je uspešno dodat u korpu.'));
                this.props.socketIOClient.emit('cartInfo', {});
                if (this.state.buyNow) {
                    this.props[0].history.push('/cart');
                }
            } else {
                this.props.showInfoMessage(this.props.translate('Nema dovoljno na stanju.'), true);

            }
        });

        this.props.socketIOClient.on('addToWishlist', (data) => {
            console.log(data);
            this.props.showInfoMessage(this.props.translate('Artikal je dodat u listu omiljenih.'));

        });




        // this.props.socketIOClient.emit('fetchProduct', { _id: this.props[0].match.params.id });
        this.props.socketIOClient.emit('fetchProduct', this.props[0].match.params.slug ? { slug: this.props[0].match.params.slug } : { _id: this.props[0].match.params.id });

        // alert(true);

    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("fetchProduct");
        this.props.socketIOClient.removeAllListeners("addToWishlist");
        this.props.socketIOClient.removeAllListeners("addToCart");

    }



    addToWishlist() {
        if (!this.props.uData) {
            this.props[0].history.push('/login');
            return;
        }

        this.props.socketIOClient.emit('addToWishlist', { _id: this.state.product._id });
    }


    addToCart() {
        this.props.socketIOClient.emit('addToCart', { _id: this.state.product._id, quantity: parseInt(this.state.quantity), package: this.state.package });

    }


    onTouchStart(event) {
        var x = event.clientX;
        var y = event.clientY;
        if (!this.state._startSwipePos) {
            this.setState({
                _startSwipePos: x,
                _startSwipePosY: y,
                _startLeft: this.carousel.scrollLeft
            });
        }
    }

    onTouchEnd() {
        this.setState({
            _startSwipePos: null,
            _startSwipePosY: null,
            _startLeft: null
        });
    }

    onTouchMove(event) {
        var x = event.clientX;
        var y = event.clientY;

        if (this.state._startSwipePos) {
            this.carousel.scrollLeft = this.state._startLeft - (x - this.state._startSwipePos);
        }

        this.setState({
            _swipePos: x
        });


    }
    checkSection = (section) => {
        let sectionExists = false;
        if (section && Object.keys(section).length) {
            for (var key in section) {
                if (section[key] && typeof section[key] === 'object') {
                    for (var key2 in section[key]) {
                        if (section[key][key2]) {
                            sectionExists = true;
                        }
                    }
                } else if (section[key]) {
                    sectionExists = true;
                }

            }
        }

        return sectionExists;
    }

    render() {
        let product = this.state.product;
        const settings = {
            dots: false,
            infinite: true,
            speed: 1500,
            slidesToShow: window.innerWidth < 768 ? 2 : 4,
            slidesToScroll: 1,
            autoplay: true
        };
        const sliderSettings = {
            dots: false,
            infinite: true,
            speed: 1500,
            slidesToShow: window.innerWidth < 768 ? 4 : 2,
            slidesToScroll: 1,
            vertical: window.innerWidth < 768 ? null : true,
            verticalSwiping: window.innerWidth < 768 ? null : true,
            autoplay: true


        };
        const commentsSettings = {
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 1500,
            slidesToShow: window.innerWidth < 768 ? 1 : window.innerWidth >= 768 && window.innerWidth < 992 ? 2 : 5,
            slidesToScroll: 1,
            autoplay: true

        };


        const meta = {
            title: Object.translate(product, 'Name', this.props.lang) + this.props.translate(' | rossen-shop.ch – Harmonija između čovjeka i prirode'),
            description: striptags(Object.translate(product, 'Description', this.props.lang)),
            meta: {
                charset: 'utf-8',
                name: {
                    'og:url': `https://rossen-shop.ch/${product.Alias}/${product._id}`,
                    'og:title': Object.translate(product, 'Name', this.props.lang),
                    'og:image': Object.translate(product, 'Images', this.props.lang) && Object.translate(product, 'Images', this.props.lang)[0],
                    'og:description': striptags(Object.translate(product, 'Description', this.props.lang))
                }
            }
        };

        return (
            <div className="detail-wrap">


                <DocumentMeta {...meta} />
                <Container className="article-box article-detail">



                    <Row>
                        <Col lg="7" className="article-image">

                            <div className="slide-show">
                                {Object.translate(product, 'Images', this.props.lang) ?
                                    <div className="images">
                                        <Slider {...sliderSettings}>
                                            {
                                                Object.translate(product, 'Images', this.props.lang).map((image, idx) => {
                                                    return (

                                                        <div className="image" onClick={() => this.setState({ previewImage: image })}>
                                                            <img src={image} />
                                                        </div>


                                                    )
                                                })
                                            }

                                        </Slider>

                                    </div>

                                    :
                                    null
                                }
                                <div className="preview">
                                    <img onError={() => { this.setState({ imageError: true }) }} src={!this.state.imageError ? this.state.previewImage ? this.state.previewImage : image : image} />
                                </div>
                            </div>
                            {product && product.YouTubeId ?
                                // <iframe className="detail-video" width={"100%"} height={typeof window != 'undefined' && window.innerWidth < 500 ? 220 : 365} src={`https://www.youtube.com/embed/${Object.translate(product, 'YouTubeId', this.props.lang)}?autoplay=true&rel=0`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <YouTube
                                    videoId={Object.translate(product, 'YouTubeId', this.props.lang)}
                                    opts={{ height: typeof window != 'undefined' && window.innerWidth < 500 ? 220 : 365, width: "100%", playerVars: { autoplay: 1, rel: 0 } }}
                                    onEnd={(event) => {
                                        if (event && event.target) {
                                            if (event.target.seekTo) event.target.seekTo(0);
                                            if (event.target.stopVideo) event.target.stopVideo();
                                        }
                                    }}
                                />
                                :
                                null
                            }
                        </Col>


                        <Col lg="5">
                            {this.props.breadcrumb && this.props.breadcrumb.length ? <Link className="category" to={`${this.props.breadcrumb[this.props.breadcrumb.length - 1].link}`}>{Object.translate(this.props.breadcrumb[this.props.breadcrumb.length - 1], 'name', this.props.lang)}</Link> : null}
                            {/*<h3>Šifra: {product.Alias} {product.StockLevel > 0 ?
                                                                <p className="stock"> <div className="in-stock"></div> Na stanju</p>
                                                                :
                                                                <p className="stock"> <div className="out-of-stock"></div> Nema na stanju</p>
                                                            }</h3>*/}

                            <h2>{Object.translate(product, 'Name', this.props.lang)}  </h2>


                            <div className="shortDescription" dangerouslySetInnerHTML={{ __html: Object.translate(product, 'ShortDescription', this.props.lang) }}>

                            </div>


                            {product && product.Composition ?
                                <>
                                    <button className='composition-button' onClick={() => this.setState({ showComposition: !this.state.showComposition })}>{this.props.translate('Sastav')}</button>
                                    {
                                        this.state.showComposition ?
                                            <div className="composition" dangerouslySetInnerHTML={{ __html: Object.translate(product, 'Composition', this.props.lang) }}></div>
                                            :
                                            null
                                    }
                                </>
                                :
                                null

                            }

                            {/*<div className="packages">
                                {
                                    product.package && product.package.map((item, idx) => {
                                        return (
                                            <div key={idx} onClick={() => this.setState({ package: item })} className={this.state.package && this.state.package.name == item.name ? 'package active' : 'package'}>
                                                <p> {item.name}</p>
                                                <p>{item.price && item.price.formatPrice(this.props.currency)}</p>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                            */}
                            {/* <UncontrolledDropdown className="package-dropdown">
                                <DropdownToggle>
                                    {Object.translate(this.state.package, 'name', this.props.lang)}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {
                                        product.package && product.package.map((item, idx) => {
                                            return (
                                                <DropdownItem key={idx} onClick={() => this.setState({ package: item })}>{Object.translate(item, 'name', this.props.lang)} <span> {item.price && item.price.formatPrice(this.props.currency)} </span> </DropdownItem>

                                            )
                                        })
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown> */}

                            <div className='variations-table'>
                                {
                                    product.package && product.package.map((item, idx) => {
                                        if (Object.translate(item, 'name', this.props.lang) && item.price) {
                                            let upDiscount = item.upDiscount;
                                            if (this.state.product.discount && !item.discount && !upDiscount){
                                                let up_price = item.price / ( (1 - this.state.product.discount / 100));
                                                upDiscount = (up_price / item.price) * 100 - 100
                                                console.log(upDiscount)
                                            }
                                    
                                            return (
                                                <div className={item && this.state.package && Object.translate(this.state.package, 'name', this.props.lang) == Object.translate(item, 'name', this.props.lang) ? 'active' : ''} key={idx} onClick={() => this.setState({ package: item })}>{Object.translate(item, 'name', this.props.lang)} <span> {item.discount || upDiscount ? <span className="old-price">{item.price && (item.price * (1 + (upDiscount || item.discount) / 100)).formatPrice(this.props.currency)}</span> : null} {item.price && item.price.formatPrice(this.props.currency)} </span>  </div>

                                            )
                                        }
                                    })
                                }


                            </div>


                            {product.StockLevel > 0 && this.state.package && this.state.package.price ?
                                <div className="actions">
                                    <div className="qunatity-wrap">
                                        <div className="quantity-input">
                                            <span>{this.props.translate('Količina')}</span>
                                            <div>
                                                <button className="dec" onClick={() => { if (this.state.quantity - 1 >= 0) this.setState({ quantity: this.state.quantity - 1 }) }}></button>
                                                <input type="text" value={this.state.quantity} onChange={(e) => this.setState({ quantity: e.target.value })} />
                                                <button className="inc" onClick={() => this.setState({ quantity: this.state.quantity + 1 })}></button>

                                            </div>
                                        </div>

                                        <button className="wishlist-btn" onClick={this.addToWishlist}><Isvg src={wishlistIcon} /></button>
                                    </div>
                                    <div className="price">

                                        <p>{this.props.translate('Ukupna cijena:')}</p>
                                        <span> {this.state.package && this.state.package.price && (this.state.package.price * this.state.quantity).formatPrice(this.props.currency)}</span>

                                    </div>

                                    <div className="buy-buttons">

                                        <button className="button button-black" onClick={() => {
                                            this.setState({
                                                buyNow: true
                                            }, this.addToCart)
                                        }}>{this.props.translate('Poruči sada')}</button>
                                        <button className="button button-black add-to-cart-btn" onClick={this.addToCart}><Isvg src={cart_icon} /> {this.props.translate('Dodaj u korpu')}</button>

                                    </div>
                                </div>
                                : null}


                            {/*<h2 className="description-title">Detaljan opis</h2>
                            <div className="description" dangerouslySetInnerHTML={{ __html: product.Description }}>
                                    </div>*/}

                        </Col>


                    </Row>
                </Container>
                {
                    product && product.section && product.section[0] && this.checkSection(product.section[0]) ?
                        <section className="detail-section1">
                            <img src={window.innerWidth < 768 ? Object.translate(product, 'section[0].MobileImage', this.props.lang) : window.innerWidth >= 768 && window.innerWidth < 992 ? Object.translate(product, 'section[0].TabletImage', this.props.lang) : Object.translate(product, 'section[0].Image', this.props.lang)} />
                            <div className="container-wrap">
                                <Container>
                                    <Row>
                                        <Col lg={{ size: product.section[0].BlockWidthDesktop, offset: product.section[0].BlockOffsetDesktop }} xs={{ size: product.section[0].BlockWidthMobile, offset: product.section[0].BlockOffsetMobile }}>
                                            <h3 style={{ color: product.section[0].TitleColor }}>{Object.translate(product.section[0], 'Title', this.props.lang)}</h3>
                                            <div className="block" style={{ backgroundColor: product.section[0].BlockColor }} dangerouslySetInnerHTML={{ __html: Object.translate(product.section[0], 'Content', this.props.lang) }}>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>

                        :

                        null
                }
                {
                    product && product.section && product.section[1] && this.checkSection(product.section[1]) ?

                        <section className="detail-section2">
                            <img src={window.innerWidth < 768 ? Object.translate(product, 'section[1].MobileImage', this.props.lang) : window.innerWidth >= 768 && window.innerWidth < 992 ? Object.translate(product, 'section[1].TabletImage', this.props.lang) : Object.translate(product, 'section[1].Image', this.props.lang)} />
                            <div className="container-wrap">
                                <Container>
                                    <Row>
                                        <Col lg={{ size: product.section[1].BlockWidthDesktop, offset: product.section[1].BlockOffsetDesktop }} xs={{ size: product.section[1].BlockWidthMobile, offset: product.section[1].BlockOffsetMobile }}>
                                            {product.section[1].Title ? <h3 style={{ color: product.section[1].TitleColor }}>{Object.translate(product.section[1], 'Title', this.props.lang)}</h3> : null}

                                            <div className="block" style={{ backgroundColor: product.section[1].BlockColor }} dangerouslySetInnerHTML={{ __html: Object.translate(product.section[1], 'Content', this.props.lang) }}>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                        :
                        null
                }

                {product && product.section && product.section[3] && this.checkSection(product.section[3]) ?
                    <section className="detail-section4">
                        <div className="container-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <h2>{Object.translate(product.section[3], 'Title', this.props.lang)}</h2>
                                    </Col>
                                    {
                                        product.section[3].blocks && product.section[3].blocks.map((item, idx) => {
                                            if (idx % 2 == 0 && window.innerWidth >= 768) {
                                                return (
                                                    <Col lg="6">
                                                        <h3 style={{ color: item.TitleColor }}>{Object.translate(item, 'Title', this.props.lang)}</h3>
                                                        <div dangerouslySetInnerHTML={{ __html: Object.translate(item, 'Content', this.props.lang) }}></div>
                                                        <img src={Object.translate(item, 'Image', this.props.lang)} />
                                                    </Col>

                                                )
                                            } else {
                                                return (
                                                    <Col lg="6">
                                                        <img src={Object.translate(item, 'Image', this.props.lang)} />
                                                        <h3 style={{ color: item.TitleColor }}>{Object.translate(item, 'Title', this.props.lang)}</h3>
                                                        <div dangerouslySetInnerHTML={{ __html: Object.translate(item, 'Content', this.props.lang) }}></div>
                                                    </Col>

                                                )
                                            }
                                        })
                                    }

                                </Row>
                            </Container>
                        </div>
                    </section>

                    :

                    null
                }


                {
                    product && product.section && product.section[2] && Object.translate(product.section[2], 'Content', this.props.lang) && this.checkSection(product.section[2]) ?


                        <section className="detail-section3" style={{ backgroundColor: product.section[2].BackgroundColor }}>
                            <div className="container-wrap">
                                <Container>
                                    <Row>
                                        <Col lg={{ size: product.section[2].BlockWidthDesktop, offset: product.section[2].BlockOffsetDesktop, order: 0 }} xs={{ size: product.section[2].BlockWidthMobile, offset: product.section[2].BlockOffsetMobile, order: 1 }}>
                                            <h3 style={{ color: product.section[2].TitleColor }}>{Object.translate(product.section[2], 'Title', this.props.lang)}</h3>
                                            <div className="block" style={{ backgroundColor: product.section[2].BlockColor }} dangerouslySetInnerHTML={{ __html: Object.translate(product.section[2], 'Content', this.props.lang) }}>
                                            </div>
                                        </Col>
                                        <Col lg={{ size: product.section[2].ImageWidthDesktop, offset: product.section[2].ImageOffsetDesktop, order: 1 }} xs={{ size: product.section[2].ImageWidthMobile, offset: product.section[2].ImageOffsetMobile, order: 0 }}>
                                            <img src={Object.translate(product, 'section[2].Image', this.props.lang)} />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                        :
                        null
                }


                {
                    product && product.section && product.section[4] && this.checkSection(product.section[4]) ?
                        <section className="detail-section5">
                            <img src={window.innerWidth < 768 ? Object.translate(product, 'section[4].MobileImage', this.props.lang) : window.innerWidth >= 768 && window.innerWidth < 992 ? Object.translate(product, 'section[4].TabletImage', this.props.lang) : Object.translate(product, 'section[4].Image', this.props.lang)} />
                            <div className="container-wrap">
                                <Container>
                                    <Row>
                                        <Col lg={{ size: product.section[4].BlockWidthDesktop, offset: product.section[4].BlockOffsetDesktop }} xs={{ size: product.section[4].BlockWidthMobile, offset: product.section[4].BlockOffsetMobile }}>
                                            <h3 style={{ color: product.section[4].TitleColor }}>{Object.translate(product.section[4], 'Title', this.props.lang)}</h3>
                                            <div className="block" style={{ backgroundColor: product.section[4].BlockColor }} dangerouslySetInnerHTML={{ __html: Object.translate(product.section[4], 'Content', this.props.lang) }}>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>

                        :

                        null
                }

                <section className="section comments">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h2>
                                    <span>{this.props.translate('UTISCI NAŠIH')}</span> {this.props.translate('KORISNIKA')}
                                </h2>
                            </Col>

                        </Row>
                    </Container>
                    <Slider {...commentsSettings}>
                        {
                            Object.translate(product, 'Comments', this.props.lang) && Object.translate(product, 'Comments', this.props.lang).map((image, idx) => {
                                return (

                                    <div className="image">
                                        <img src={image} />
                                    </div>


                                )
                            })
                        }

                    </Slider>

                </section>






                {product && product.bundleProducts && product.bundleProducts.length ?
                    <section className="section section-articles">
                        <Container>
                            <Row>
                                <Col lg="12">
                                    <h2>
                                        {/* {this.props.translate('POVEZANI')} <span>{this.props.translate('PROIZVODI')}</span> */}
                                        <span>{this.props.translate('MOGLO BI VAM SE')}</span> {this.props.translate('SVIDJETI')}
                                    </h2>
                                </Col>

                                <Col lg="12" className="slider-container">
                                    <Slider {...settings}>
                                        {
                                            product && product.bundleProducts && product.bundleProducts.map((item, idx) => {
                                                return (
                                                    <Article addToCart={() => this.props.addToCart(item)} lang={this.props.lang}
                                                        _id={item._id}
                                                        slug={item.slug}
                                                        alias={item.Alias}
                                                        image={Object.translate(item, 'Images', this.props.lang) ? Object.translate(item, 'Images', this.props.lang)[0] : null}
                                                        title={item.Name}
                                                        package={item.package} showDiscount={item.ShowDiscountPrice}
                                                        currency={this.props.currency}
                                                        discount={item.discount}
                                                        showDiscount={item.ShowDiscountPrice}
                                                        price={item.price ? item.price : 0}
                                                        inStock={item.StockLevel > 0 ? true : false}
                                                        translate={this.props.translate}>
                                                    </Article>
                                                )
                                            })
                                        }

                                    </Slider>

                                </Col>


                            </Row>
                        </Container>
                    </section>
                    :
                    null
                }

                <Newsletter {...this.props} {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(DetailPage));
