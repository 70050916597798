import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import Footer from '../containers/footer';

import img1 from '../assets/skinconsult/img1.png';
import img2 from '../assets/skinconsult/img2.png';
import img3 from '../assets/skinconsult/img3.png';
import img4 from '../assets/skinconsult/img4.png';


import img1jpg from '../assets/skinconsult/img1.jpg';
import img2jpg from '../assets/skinconsult/img2.jpg';
import img3jpg from '../assets/skinconsult/img3.jpg';


import selfie from '../assets/skinconsult/selfie.svg';
import resume from '../assets/skinconsult/resume.svg';
import face from '../assets/skinconsult/face.svg';
import imageGallery from '../assets/skinconsult/image-gallery.svg';
import * as faceapi from 'face-api.js';
import test from '../assets/skinconsult/test.jpg';
import loader from '../assets/skinconsult/loader.svg';
import rlogo from '../assets/skinconsult/rlogo.svg';
import "pathseg";

import Particles from "react-tsparticles";



import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

function getObjectFitSize(contains /* true = contain, false = cover */, containerWidth, containerHeight, width, height) {
    var doRatio = width / height;
    var cRatio = containerWidth / containerHeight;
    var targetWidth = 0;
    var targetHeight = 0;
    var test = contains ? (doRatio > cRatio) : (doRatio < cRatio);

    if (test) {
        targetWidth = containerWidth;
        targetHeight = targetWidth / doRatio;
    } else {
        targetHeight = containerHeight;
        targetWidth = targetHeight * doRatio;
    }

    return {
        width: targetWidth,
        height: targetHeight,
        x: (containerWidth - targetWidth) / 2,
        y: (containerHeight - targetHeight) / 2
    };
}




class SkinConsult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            items: [],
            answers: {},
            answeredQuestions: [],
            showMore: {},
            allProducts: [],
            products: []

        };

    }



    componentDidUpdate(prevProps, prevState) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

        if (prevState.step != this.state.step) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }


    }

    registerSocketIOEvents = () => {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });


        this.props.socketIOClient.on('fetchQuestionary', (data) => {
            console.log(data);
            this.setState({
                items: data.items,
                allProducts: data.products,
            })
        });



        this.props.socketIOClient.emit("fetchQuestionary", {});

    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("fetchQuestionary");

        if (window.FB && window.FB.CustomerChat) {
            window.FB.CustomerChat.show(true);
        }

    }





    takePhoto = () => {

        var canvas = document.createElement('canvas');

        var canvasWidth = window.innerWidth;
        let canvasHeight = window.innerHeight;

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        document.body.appendChild(canvas);

        let object = getObjectFitSize(true, canvasWidth, canvasHeight, this.video.videoWidth, this.video.videoHeight);

        var context = canvas.getContext('2d');

        context.translate(canvas.width, 0);
        context.scale(-1, 1);


        context.drawImage(this.video, object.x, object.y, object.width, object.height);

        //var data = canvas.toDataURL('image/png');
        //console.log(data);

        this.state.stream.getTracks().forEach(track => track.stop());

        this.setState({
            selfie: canvas.toDataURL('image/png'),
            stream: null
        }, async () => {


            const detection = await faceapi.detectSingleFace(this.image, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks(true);;

            if (!detection) {
                this.setState({
                    error: true,
                    //selfie: null
                })
                return;
            }



            console.log(detection)


            let positions = detection.landmarks.getJawOutline();

            let newPositions = [];

            for (let i = 0; i < positions.length / 2; i++) {
                newPositions.push(positions[i]);
            }

            newPositions.push({
                x: newPositions[newPositions.length - 1].x,
                y: newPositions[0].y
            })

            positions = newPositions;
            console.log(positions)

            let dot1 = { x: positions[0].x + (positions[positions.length - 1].x - positions[0].x) / 5, y: positions[0].y + (positions[Math.floor(positions.length / 2)].y - positions[0].y) / 6 }
            let dot2 = { x: positions[positions.length - 1].x - (positions[positions.length - 1].x - positions[0].x) / 5, y: positions[0].y + (positions[Math.floor(positions.length / 2)].y - positions[0].y) / 6 }
            let dot3 = { x: positions[0].x + (positions[positions.length - 1].x - positions[0].x) / 2, y: positions[Math.floor(positions.length / 2)].y - (positions[Math.floor(positions.length / 2)].y - positions[0].y) / 6 }



            let path = `M${positions[0].x} ${positions[0].y} L${positions[1].x} ${positions[1].y} `

            for (let i = 2; i < positions.length; i++) {
                path += `${positions[i].x} ${positions[i].y} `
            }


            let minX = positions[0].x, maxX = positions[0].x;

            for (let i = 0; i < positions.length; i++) {
                if (positions[i].x > maxX) {
                    maxX = positions[i].x;
                }
                if (positions[i].x < minX) {
                    minX = positions[i].x;
                }

            }

            let svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${canvas.width}" height="${canvas.height}" viewBox="0 0 ${canvas.width} ${canvas.height}"><path fill="none" stroke="#000" d="${path} Z" /></svg>`
            console.log(svg)

            console.log(`data:image/svg+xml;base64,${window.btoa(svg)}`)
            this.setState({
                svg: `data:image/svg+xml;base64,${window.btoa(svg)}`,
                faceWidth: maxX - minX
            })
            setTimeout(() => {
                this.setState({
                    step: 2
                })
            }, 8000);


        })

    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    selectFile = async (e) => {
        if (e.target && e.target.files && e.target.files[0]) {

            var canvas = document.createElement('canvas');
            var canvasWidth = window.innerWidth;
            let canvasHeight = window.innerHeight;

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
            document.body.appendChild(canvas);


            var context = canvas.getContext('2d');


            var image = new Image();
            image.onload = () => {
                let object = getObjectFitSize(true, canvasWidth, canvasHeight, image.width, image.height);

                console.log(object);
                context.drawImage(image, object.x, object.y, object.width, object.height);

                this.setState({
                    selfie: canvas.toDataURL('image/png'),
                    stream: null
                }, async () => {

                    const detection = await faceapi.detectSingleFace(this.image, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks(true);;


                    if (!detection) {
                        this.setState({
                            error1: true,
                            //selfie: null
                        })
                        return;
                    }
        

                    console.log(detection)


                    let positions = detection.landmarks.getJawOutline();

                    let newPositions = [];

                    for (let i = 0; i < positions.length / 2; i++) {
                        newPositions.push(positions[i]);
                    }
                    newPositions.push({
                        x: newPositions[newPositions.length - 1].x,
                        y: newPositions[0].y
                    })


                    positions = newPositions;
                    console.log(positions)

                    let dot1 = { x: positions[0].x + (positions[positions.length - 1].x - positions[0].x) / 5, y: positions[0].y + (positions[Math.floor(positions.length / 2)].y - positions[0].y) / 6 }
                    let dot2 = { x: positions[positions.length - 1].x - (positions[positions.length - 1].x - positions[0].x) / 5, y: positions[0].y + (positions[Math.floor(positions.length / 2)].y - positions[0].y) / 6 }
                    let dot3 = { x: positions[0].x + (positions[positions.length - 1].x - positions[0].x) / 2, y: positions[Math.floor(positions.length / 2)].y - (positions[Math.floor(positions.length / 2)].y - positions[0].y) / 6 }



                    let path = `M${positions[0].x} ${positions[0].y} L${positions[1].x} ${positions[1].y} `

                    for (let i = 2; i < positions.length; i++) {
                        path += `${positions[i].x} ${positions[i].y} `
                    }


                    let minX = positions[0].x, maxX = positions[0].x;

                    for (let i = 0; i < positions.length; i++) {
                        if (positions[i].x > maxX) {
                            maxX = positions[i].x;
                        }
                        if (positions[i].x < minX) {
                            minX = positions[i].x;
                        }

                    }

                    let svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${window.innerWidth}" height="${window.innerHeight}" viewBox="0 0 ${window.innerWidth} ${window.innerHeight}"><path fill="none" stroke="#000" d="${path} Z" /></svg>`
                    console.log(svg)

                    console.log(`data:image/svg+xml;base64,${window.btoa(svg)}`)
                    this.setState({
                        svg: `data:image/svg+xml;base64,${window.btoa(svg)}`,
                        faceWidth: maxX - minX
                    })
                    setTimeout(() => {
                        this.setState({
                            step: 2
                        })
                    }, 8000);





                })

            };
            image.src = window.URL.createObjectURL(e.target.files[0])// await this.toBase64(e.target.files[0])

        }




    }

    getVideoStream = (callback) => {
        navigator.mediaDevices.getUserMedia(
            { video: true }).then(
                stream => {
                    this.setState({
                        stream: stream,
                        selfie: null
                    }, () => {
                        if (this.video) {
                            this.video.muted = true;
                            this.video.autoplay = true;
                            this.video.playsinline = true;
                            this.video.srcObject = stream;

                            if (callback) {
                                callback();
                            }
                        }
                    })
                }
            ).catch(e => {
                alert('Greska!');
            });
    }


    async componentDidMount() {
        await faceapi.nets.tinyFaceDetector.load('/')
        await faceapi.nets.faceLandmark68TinyNet.load('/')
        //await faceapi.nets.ageGenderNet.load('/')


        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


        if (window.FB && window.FB.CustomerChat) {
            window.FB.CustomerChat.hide();
        }


    }


    getQuestion = (id) => {
        for (let j = 0; j < this.state.items.length; j++) {
            if (this.state.items[j].id == id) {
                return this.state.items[j];
            }
        }


        return {};
    }
    getAnswer = (id) => {
        for (let j = 0; j < this.state.items.length; j++) {
            if (this.state.items[j].answers) {
                for (let k = 0; k < this.state.items[j].answers.length; k++) {
                    if (this.state.items[j].answers[k].id == id) {
                        return this.state.items[j].answers[k];
                    }
                }
            }
        }


        return {};
    }


    answerQuestion(questionId, answer) {
        let question = this.getQuestion(questionId);
        if (!question) {
            return;
        }

        let answers = this.state.answers;

        if (question.type == 1) {
            if (!answers[question.id]) {
                answers[question.id] = null;
            }

            answers[question.id] = answer;
        } else if (question.type == 2) {
            if (!answers[question.id]) {
                answers[question.id] = [];
            }

            if (answers[question.id].indexOf(answer) !== -1) {
                answers[question.id].splice(answers[question.id].indexOf(answer), 1);
            } else {
                answers[question.id].push(answer);
            }

        } else if (question.type == 3) {
            answers[question.id] = answer;
        }

        // clear not vissible fields

        for (let j = 0; j < this.state.items.length; j++) {
            if (!this.checkQuestionCondition(this.state.items[j].id)) {
                delete answers[this.state.items[j].id];
            }
        }



        //console.log(answers)
        this.setState({
            answers
        }, () => {

        })
    }

    checkQuestionCondition(questionId) {
        let question = this.getQuestion(questionId);
        if (!question) {
            return;
        }

        if (!question.conditions) {
            return true;
        }

        if (!question.conditions.length) {
            return true;
        }


        for (let i = 0; i < question.conditions.length; i++) {
            if (!question.conditions[i].conditionQuestion) {
                continue;
            }

            let conditionQuestion = this.getQuestion(question.conditions[i].conditionQuestion);
            if (!conditionQuestion) {
                continue;
            }

            if (conditionQuestion.type == 1) {
                if (this.state.answers[conditionQuestion.id] == question.conditions[i].conditionAnswer) {
                    return true;
                }
            } else if (conditionQuestion.type == 2) {
                if (this.state.answers[conditionQuestion.id] && this.state.answers[conditionQuestion.id].indexOf(question.conditions[i].conditionAnswer) !== -1) {
                    return true;
                }

            } else if (conditionQuestion.type == 3) {
                if (this.state.answers[conditionQuestion.id]) {
                    return true;
                }

            }
        }
        return false;
    }


    getProducts = () => {
        let products = {};
        let _started = false;

        let firstProducts = {};



        for (let i = 0; i < this.state.items.length; i++) {
            if (this.checkQuestionCondition(this.state.items[i].id) && this.state.answers[this.state.items[i].id]) {
                let _products = {};
                if (this.state.items[i].type == 1) {
                    let answer = this.getAnswer(this.state.answers[this.state.items[i].id]);
                    if (answer.products) {
                        for (let j = 0; j < answer.products.length; j++) {

                            _products[answer.products[j]] = true;
                        }
                    }
                } else if (this.state.items[i].type == 2) {
                    for (let k = 0; k < this.state.answers[this.state.items[i].id].length; k++) {
                        let answer = this.getAnswer(this.state.answers[this.state.items[i].id][k]);
                        if (answer.products) {
                            for (let j = 0; j < answer.products.length; j++) {
                                _products[answer.products[j]] = true;
                            }
                        }

                    }
                } else if (this.state.items[i].type == 3) {
                    for (let k = 0; k < this.state.items[i].answers.length; k++) {
                        let min = parseInt(this.state.items[i].answers[k].name.split('-')[0]);
                        let max = parseInt(this.state.items[i].answers[k].name.split('-')[1]);

                        if (parseInt(this.state.answers[this.state.items[i].id]) >= min && parseInt(this.state.answers[this.state.items[i].id]) <= max) {
                            let answer = this.state.items[i].answers[k];
                            if (answer.products) {
                                for (let j = 0; j < answer.products.length; j++) {
                                    _products[answer.products[j]] = true;
                                }
                            }
                        }
                    }
                }


                if (!_started) {
                    for (var product in _products) {
                        firstProducts[product] = true;
                        //products[product] = true;
                    }
                } else {
                    for (var product in _products) {
                        if (firstProducts[product]) {
                            products[product] = true;
                        } else {
                            delete products[product];
                        }
                    }
                }

                if (!_started) {
                    _started = true;
                }

            }
        }

        let res = [];
        for (var _id in products) {
            for (let i = 0; i < this.state.allProducts.length; i++) {
                if (_id == this.state.allProducts[i]._id) {
                    res.push(this.state.allProducts[i]);
                    break;
                }
            }
        }

        this.setState({ products: res })
    }


    render() {

        let currentQuestion = null;
        let nextQuestion = null;

        for (let i = 0; i < this.state.items.length; i++) {
            if (this.checkQuestionCondition(this.state.items[i].id) && this.state.answeredQuestions.indexOf(this.state.items[i].id) == -1) {
                currentQuestion = this.state.items[i];
                break;
            }
        }

        let track = false;
        for (let i = 0; i < this.state.items.length; i++) {
            if (track && this.checkQuestionCondition(this.state.items[i].id) && this.state.answeredQuestions.indexOf(this.state.items[i].id) == -1) {
                nextQuestion = this.state.items[i];
                break;
            }

            if (this.checkQuestionCondition(this.state.items[i].id) && this.state.answeredQuestions.indexOf(this.state.items[i].id) == -1) {
                track = true;
            }
        }




        return (
            <div className="skinconsult-wrap">


                <input type="file" ref={(node) => this.fileInput = node} style={{ opacity: 0, pointerEvents: 'none' }} onChange={(e) => this.selectFile(e)} />
                {this.state.step === 2 ?
                    <div className={!nextQuestion ? "step-2 step-3" : "step-2"}>
                        <div className="progress-indicator">
                            <p className="progress-1">{this.props.translate('1. SELFIE')}</p>
                            <p className="progress-2">{this.props.translate('2. UPITNIK')}</p>
                            <p className="progress-3">{this.props.translate('3. REZULTATI')}</p>
                            <div>
                                <div className="dot-1">
                                </div>
                                <div className="dot-2">
                                </div>
                            </div>
                        </div>

                        <Container>
                            <Row>
                                <Col xs="12">
                                    <div className="questionary">
                                        {
                                            currentQuestion ?
                                                <div className={currentQuestion.image ? "question question-with-image" : "question"}>
                                                    {
                                                        currentQuestion.image ?

                                                            <img src={'https://rossen-shop.ch/' + currentQuestion.image} />
                                                            :
                                                            null
                                                    }

                                                    <h6>{currentQuestion.name}</h6>

                                                    {
                                                        currentQuestion.type == 3 ?
                                                            <input type="text" placeholder="0" value={this.state.answers[currentQuestion.id]} onChange={(e) => this.answerQuestion(currentQuestion.id, e.target.value)} onKeyUp={(e) => {
                                                                if (e.keyCode === 13) {
                                                                    window.scrollTo({ behavior: 'smooth', top: 0 })

                                                                    let answeredQuestions = this.state.answeredQuestions;
                                                                    answeredQuestions.push(currentQuestion.id);
                                                                    this.setState({ answeredQuestions })

                                                                    if (!nextQuestion) {
                                                                        this.getProducts();
                                                                    }


                                                                }

                                                            }} />

                                                            :

                                                            null
                                                    }

                                                    {
                                                        currentQuestion.type == 1 || currentQuestion.type == 2 ?
                                                            currentQuestion.circleSelector ?
                                                                <div className="circle-options-wrap">
                                                                    {
                                                                        currentQuestion.answers.map((answer, aidx) => {
                                                                            return (
                                                                                <button style={{
                                                                                    top: 170 + 80 * Math.sin(-Math.PI / 2 + aidx * (Math.PI * 2) / currentQuestion.answers.length) + (Math.sin(-Math.PI / 2 + aidx * (Math.PI * 2) / currentQuestion.answers.length) > 0 ? 40 : -80),
                                                                                    left: 170 + 60 * Math.cos(-Math.PI / 2 + aidx * (Math.PI * 2) / currentQuestion.answers.length) + (Math.round(Math.cos(-Math.PI / 2 + aidx * (Math.PI * 2) / currentQuestion.answers.length), 1) < 0 ? -(76 + 38) : Math.round(Math.cos(-Math.PI / 2 + aidx * (Math.PI * 2) / currentQuestion.answers.length), 1) == 0 ? -38 : 38)
                                                                                }} className={((currentQuestion.type == 1 && this.state.answers[currentQuestion.id] == answer.id) || (currentQuestion.type == 2 && this.state.answers[currentQuestion.id] && this.state.answers[currentQuestion.id].indexOf(answer.id) !== -1)) ? "answer active" : "answer"} onClick={() => {
                                                                                    this.answerQuestion(currentQuestion.id, answer.id);
                                                                                }}> {answer.name} </button>
                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        currentQuestion.answers.map((answer, aidx) => {
                                                                            return (
                                                                                <button style={{
                                                                                    top: 170 + 89 * Math.sin(-Math.PI / 2 + aidx * (Math.PI * 2) / currentQuestion.answers.length) + (Math.sin(-Math.PI / 2 + aidx * (Math.PI * 2) / currentQuestion.answers.length) > 0 ? 14 : -28),
                                                                                    left: 170 + 89 * Math.cos(-Math.PI / 2 + aidx * (Math.PI * 2) / currentQuestion.answers.length) + (Math.round(Math.cos(-Math.PI / 2 + aidx * (Math.PI * 2) / currentQuestion.answers.length), 1) < 0 ? -(14 + 7) : Math.round(Math.cos(-Math.PI / 2 + aidx * (Math.PI * 2) / currentQuestion.answers.length), 1) == 0 ? -7 : 7)
                                                                                }} className={((currentQuestion.type == 1 && this.state.answers[currentQuestion.id] == answer.id) || (currentQuestion.type == 2 && this.state.answers[currentQuestion.id] && this.state.answers[currentQuestion.id].indexOf(answer.id) !== -1)) ? "dot active" : "dot"} onClick={() => {
                                                                                    this.answerQuestion(currentQuestion.id, answer.id);
                                                                                }}>  </button>
                                                                            )
                                                                        })
                                                                    }

                                                                    <div className="rlogo"><Isvg src={rlogo} /></div>
                                                                </div>

                                                                :

                                                                <div className="options">
                                                                    {
                                                                        currentQuestion.answers.map((answer) => {
                                                                            return (
                                                                                <button className={((currentQuestion.type == 1 && this.state.answers[currentQuestion.id] == answer.id) || (currentQuestion.type == 2 && this.state.answers[currentQuestion.id].indexOf(answer.id) !== -1)) ? "answer active" : "answer"} onClick={() => {
                                                                                    this.answerQuestion(currentQuestion.id, answer.id);
                                                                                }}> {answer.name} </button>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            :

                                                            null
                                                    }

                                                    {
                                                        currentQuestion.type == 1 && this.state.answers[currentQuestion.id] && this.getAnswer(this.state.answers[currentQuestion.id]).image ?
                                                            <div className="description">
                                                                <img src={'https://rossen-shop.ch' + this.getAnswer(this.state.answers[currentQuestion.id]).image} />
                                                                <div>
                                                                    <h3>{this.getAnswer(this.state.answers[currentQuestion.id]).title}</h3>
                                                                    <p>{this.getAnswer(this.state.answers[currentQuestion.id]).description}</p>
                                                                </div>
                                                            </div>
                                                            :

                                                            null
                                                    }

                                                    {
                                                        currentQuestion.type == 2 && currentQuestion.circleSelector && this.state.answers[currentQuestion.id] && this.state.answers[currentQuestion.id].length ?
                                                            this.state.answers[currentQuestion.id].map((answer, aidx) => {
                                                                if (this.getAnswer(answer).image && this.getAnswer(answer).title)
                                                                    return (
                                                                        <div className="circle-description">
                                                                            <img src={'https://rossen-shop.ch' + this.getAnswer(answer).image} />
                                                                            <div>
                                                                                <h3>{this.getAnswer(answer).title}</h3>
                                                                                {this.state.showMore[answer] ?
                                                                                    <p>{this.getAnswer(answer).description}</p>

                                                                                    :

                                                                                    <div>
                                                                                        <p>{this.getAnswer(answer).description && this.getAnswer(answer).description.length > 128 ? this.getAnswer(answer).description.substring(0, 128) + '...' : this.getAnswer(answer).description}</p>

                                                                                        {
                                                                                            this.getAnswer(answer).description && this.getAnswer(answer).description.length > 128 ?
                                                                                                <button className="show-more" onClick={() => {
                                                                                                    let showMore = this.state.showMore;
                                                                                                    showMore[answer] = true;
                                                                                                    this.setState({ showMore });
                                                                                                }}>Saznaj više</button>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    )
                                                            })
                                                            :

                                                            null
                                                    }

                                                    {this.state.answers[currentQuestion.id] ?
                                                        <button className="next-button" onClick={() => {
                                                            window.scrollTo({ behavior: 'smooth', top: 0 })

                                                            let answeredQuestions = this.state.answeredQuestions;
                                                            answeredQuestions.push(currentQuestion.id);
                                                            this.setState({ answeredQuestions })

                                                            if (!nextQuestion) {
                                                                this.getProducts();
                                                            }

                                                        }}>{nextQuestion ? this.props.translate('NASTAVITE') : this.props.translate('SAZNAJTE VAŠU RUTINU')}</button>
                                                        :
                                                        null
                                                    }
                                                </div>

                                                :

                                                null
                                        }
                                    </div>
                                </Col>

                            </Row>
                        </Container>

                        {
                            !currentQuestion ?
                                <div className="diagnose-wrap">
                                    <Container>
                                        <Row>
                                            <Col xs="12">
                                                <div className="diagnose">
                                                    <h3>{this.props.translate('DIAGNOZA VAŠE KOŽE')}</h3>
                                                    <div className="items">
                                                        {
                                                            this.state.items.reverse().map((item, idx) => {
                                                                if (this.checkQuestionCondition(item.id) && item.print) {
                                                                    if (item.type == 1) {
                                                                        return (
                                                                            <div style={{ width: `${item.width}%`, flex: `0 0 ${item.width}%`, textAlign: item.alignRight ? 'right' : 'left' }}>
                                                                                <h6>{item.printTitle}</h6>
                                                                                <p>{this.getAnswer(this.state.answers[item.id]).title}</p>
                                                                            </div>
                                                                        )
                                                                    } else if (item.type == 3) {
                                                                        return (
                                                                            <div style={{ width: `${item.width}%`, flex: `0 0 ${item.width}%`, textAlign: item.alignRight ? 'right' : 'left' }}>
                                                                                <h6>{item.printTitle}</h6>
                                                                                <p>{this.state.answers[item.id]}</p>
                                                                            </div>
                                                                        )
                                                                    } else if (item.type == 2) {
                                                                        return this.state.answers[item.id].map((answer, aidx) => {
                                                                            return (
                                                                                <div style={{ width: `${item.width}%`, flex: `0 0 ${item.width}%`, textAlign: item.alignRight ? 'right' : 'left' }}>
                                                                                    <h6>{this.getAnswer(answer).title}</h6>
                                                                                    <p>{this.getAnswer(answer).diagnose}</p>
                                                                                </div>

                                                                            )
                                                                        })
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>

                                                <div className="products">
                                                    <h3>{this.props.translate('VAŠA RUTINA')}</h3>
                                                    <div>
                                                        {
                                                            this.state.products.map((item, idx) => {
                                                                return (
                                                                    <div>
                                                                        <Link to={`/product/${item.Alias}/${item._id}`}><h6>{item.Name}</h6></Link>
                                                                        <Link to={`/product/${item.Alias}/${item._id}`}><img src={item.Images && item.Images[0]} /></Link>
                                                                        <Link to={`/product/${item.Alias}/${item._id}`} className="more">Saznaj više</Link>
                                                                        <button onClick={() => this.props.addToCart(item)}>{this.props.translate('DODAJ U KORPU')}</button>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                :

                                null
                        }
                    </div>
                    :
                    null
                }


                {this.state.step === 0 ?
                    <div className="intro">
                        <Container >
                            <Row>
                                <Col lg={{ size: 6, offset: 3 }}>
                                    <div className="content">
                                        <h3>{this.props.translate('KONSULTANT ZA')} <span>{this.props.translate('VAŠU KOŽU')}</span></h3>
                                        <p>{this.props.translate('Saznajte koja je Vaša savršena rutina za kožu')}</p>
                                        <img src={img1} />

                                        <button onClick={() => this.setState({ step: 1 })}>{this.props.translate('ZAPOČNITE!')}</button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <div className="badges">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <h3>{this.props.translate('SAMO U 3 KORAKA')}</h3>
                                        <p>{this.props.translate('Saznajte koja je Vaša savršena rutina za kožu')}</p>

                                    </Col>
                                    <Col xs="4" className="item">
                                        <Isvg src={selfie} />
                                        <p>{this.props.translate('Uslikajte selfie')}</p>
                                    </Col>
                                    <Col xs="4" className="item">
                                        <Isvg src={resume} />
                                        <p>{this.props.translate('Popunite naš upitnik')}</p>
                                    </Col>
                                    <Col xs="4" className="item">
                                        <Isvg src={face} />
                                        <p>{this.props.translate('Vaši rezultati!')}</p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    :
                    null
                }
                {this.state.step === 1 ?
                    <div className="step-1">
                        <div className="progress-indicator">
                            <p className="progress-1">{this.props.translate('1. SELFIE')}</p>
                            <p className="progress-2">{this.props.translate('2. UPITNIK')}</p>
                            <p className="progress-3">{this.props.translate('3. REZULTATI')}</p>
                            <div>
                                <div className="dot-1">
                                </div>
                                <div className="dot-2">
                                </div>
                            </div>
                        </div>

                        <Container>
                            <Row>
                                <Col xs="12">
                                    <div className="steps-wrap">
                                        <div className="steps">
                                            <div>
                                                <img src={img1jpg} />
                                                <div className="num">1.</div>
                                                <p>{this.props.translate('Skinite šminku i naočare')}</p>
                                            </div>
                                            <div>
                                                <img src={img2jpg} />
                                                <div className="num">2.</div>
                                                <p>{this.props.translate('Gledajte u kameru')}</p>
                                            </div>
                                            <div>
                                                <img src={img3jpg} />
                                                <div className="num">3.</div>
                                                <p>{this.props.translate('Potražite prirodno svjetlo')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="info">
                                        <div>
                                            <div>
                                                <button onClick={() => this.getVideoStream()}><Isvg src={selfie} /></button>
                                                <p>{this.props.translate('Uslikajte se!')}</p>
                                            </div>
                                            <div>
                                                <button onClick={() => this.fileInput.click()}><Isvg src={imageGallery} /></button>
                                                <p>{this.props.translate('Izaberite postojeću')}</p>
                                            </div>
                                        </div>
                                        <p>{this.props.translate('Vaš selfi će biti analiziran i upoređen sa preko 10.000 ocenjenih slika sa veštačkim algoritam inteligencije.')}</p>
                                    </div>

                                </Col>

                            </Row>
                        </Container>

                        {
                            this.state.stream || this.state.selfie ?
                                <div className="camera">
                                    {this.state.stream ?
                                        <video ref={(node) => this.video = node} controls={false} />
                                        :
                                        <>
                                            {this.state.selfie ?
                                                <img ref={(node) => this.image = node} src={this.state.selfie} />
                                                :
                                                null
                                            }
                                            {this.state.svg ?
                                                <Particles
                                                    id="tsparticles"
                                                    options={{
                                                        background: {
                                                            color: {
                                                                value: "#fff",
                                                            },
                                                        },
                                                        detectRetina: false,
                                                        fpsLimit: 60,
                                                        interactivity: {
                                                            detectsOn: "canvas",
                                                            events: {
                                                                onHover: {
                                                                    enable: true,
                                                                    mode: "bubble",
                                                                },
                                                                resize: true,
                                                            },
                                                            modes: {
                                                                bubble: {
                                                                    distance: 40,
                                                                    duration: 2,
                                                                    opacity: 8,
                                                                    size: 6,
                                                                    speed: 3,
                                                                },
                                                            },
                                                        },
                                                        particles: {
                                                            color: {
                                                                value: "#ffffff",
                                                                /*animation: {
                                                                    enable: true,
                                                                    speed: 20,
                                                                    sync: true,
                                                                },*/
                                                            },
                                                            lineLinked: {
                                                                blink: false,
                                                                color: "random",
                                                                consent: false,
                                                                distance: this.state.faceWidth / 2,
                                                                enable: true,
                                                                opacity: 0.6,
                                                                width: 1,
                                                            },
                                                            move: {
                                                                attract: {
                                                                    enable: false,
                                                                    rotate: {
                                                                        x: 600,
                                                                        y: 1200,
                                                                    },
                                                                },
                                                                bounce: false,
                                                                direction: "none",
                                                                enable: true,
                                                                outMode: "bounce",
                                                                random: false,
                                                                speed: 1,
                                                                straight: false,
                                                            },
                                                            number: {
                                                                density: {
                                                                    enable: false,
                                                                    area: 2000,
                                                                },
                                                                limit: 0,
                                                                value: 20,
                                                            },
                                                            opacity: {
                                                                animation: {
                                                                    enable: true,
                                                                    minimumValue: 0.05,
                                                                    speed: 2,
                                                                    sync: false,
                                                                },
                                                                random: false,
                                                                value: 1,
                                                            },
                                                            shape: {
                                                                type: "circle",
                                                            },
                                                            size: {
                                                                animation: {
                                                                    enable: false,
                                                                    minimumValue: 0.1,
                                                                    speed: 40,
                                                                    sync: false,
                                                                },
                                                                random: true,
                                                                value: 1,
                                                            },
                                                        },
                                                        polygon: {
                                                            draw: {
                                                                enable: true,
                                                                lineColor: "rgba(255,255,255,0.2)",
                                                                lineWidth: 0.3,
                                                            },
                                                            move: {
                                                                radius: 10,
                                                            },
                                                            inlineArrangement: "equidistant",
                                                            scale: 1,
                                                            type: "inside",
                                                            //url: {polygonmasklogo},
                                                            //url: "https://cdn.matteobruni.it/images/particles/smalldeer.svg",
                                                            url: this.state.svg
                                                        },
                                                    }}
                                                />
                                                :
                                                null
                                            }
                                        </>
                                    }
                                    {
                                        this.state.selfie && !this.state.svg ?
                                            <div className="loader-svg"><Isvg src={loader} /></div>

                                            :

                                            null
                                    }
                                    {
                                        !this.state.selfie ?
                                            <button onClick={() => this.takePhoto()}><Isvg src={selfie} /></button>
                                            :
                                            null
                                    }

                                    {
                                        this.state.error ?
                                            <div className="error-modal">
                                                <h6>{this.props.translate('Greška!')}</h6>
                                                <p>{this.props.translate('Slikajte se ponovo. Slika nije dobrog kvaliteta')}</p>
                                                <button onClick={() => {
                                                    this.setState({ error: null })
                                                    this.getVideoStream()
                                                }}>{this.props.translate('Pokušajte ponovo')}</button>
                                            </div>
                                            :

                                            null
                                    }
                                                                        {
                                        this.state.error1 ?
                                            <div className="error-modal">
                                                <h6>{this.props.translate('Greška!')}</h6>
                                                <p>{this.props.translate('Izaberite drugu sliku')}</p>
                                                <button onClick={() => {
                                                    this.setState({ error1: null, selfie: null })
                                                }}>{this.props.translate('Pokušajte ponovo')}</button>
                                            </div>
                                            :

                                            null
                                    }

                                </div>
                                :

                                null
                        }

                    </div>
                    :
                    null
                }

                {/*  <canvas ref={(node) => this.canvas = node} />*/}



                <Footer {...this.props} />



            </div >
        );
    }
}


export default PageWithLayout(SkinConsult);
