import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';


import account_icon from '../assets/svg/user-icon.svg'
import list_icon from '../assets/svg/list-icon.svg'
import location_icon from '../assets/svg/location-icon.svg'
import heart_icon from '../assets/svg/heart.svg'
import key_icon from '../assets/svg/key-icon.svg'
import mail_icon from '../assets/svg/mail-icon.svg'
import logout_icon from '../assets/svg/logout-icon.svg'

import cart_icon from '../assets/svg/cart-icon.svg';
import map_icon from '../assets/svg/map-icon.svg';
import map_icon_active from '../assets/svg/map-icon-active.svg';
import cart_done_icon_active from '../assets/svg/cart-done-icon-active.svg';

import cart_done_icon from '../assets/svg/cart-done-icon.svg';
import cc_icon from '../assets/svg/credit-card-icon.svg';
import delete_icon from '../assets/svg/delete-icon.svg';
import paypal_icon from '../assets/images/paypal.png';

import image from '../assets/images/no-image.jpg';
import { Player, Controls } from '@lottiefiles/react-lottie-player';

import mark1 from '../assets/svg/mark1.svg';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal, ModalHeader, ModalBody, ModalFooter

} from 'reactstrap';

import moment from 'moment';

import OrderForm from '../components/forms/orderForm';

import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';



class CheckoutForm extends React.Component {
    handleSubmit = async (event) => {
        event.preventDefault();
        const { stripe, elements } = this.props;
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (!error) {
            this.props.handleCheckout(paymentMethod.id);
        } else {
            console.log(error);
        }
    };

    render() {
        const { stripe } = this.props;
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="card-number-wrap">
                    <CardElement options={{
                        iconStyle: "solid",
                        style: {
                            base: {
                                iconColor: "#333333",
                                color: "#333333",
                                fontWeight: 500,
                                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                                fontSize: "16px",
                                fontSmoothing: "antialiased",
                                ":-webkit-autofill": {
                                    color: "#333"
                                },
                                "::placeholder": {
                                    color: "#333"
                                }
                            },
                            invalid: {
                                iconColor: "#ff0000",
                                color: "#ff0000"
                            }
                        }
                    }} />
                </div>
                <button className="pay-button" type="submit" disabled={!stripe}>
                    {'Pay'}
                </button>
            </form>
        );
    }
}

const InjectedCheckoutForm = ({ handleCheckout }) => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <CheckoutForm stripe={stripe} elements={elements} handleCheckout={handleCheckout} />
        )}
    </ElementsConsumer>
);

const stripePromise = loadStripe('pk_live_51IgU7IIqTSdkdV67BXA3UE8eApFkRcKadz4k7EIc1uX8OBViIwQoY0mGgeW2iKeJr6u0jFl5gqEqeWsXl9nl6W6o00n9G88Ry7');


class CartPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.removeCartItem = this.removeCartItem.bind(this);
        this.updateQuantity = this.updateQuantity.bind(this);
        this.updateDeliveryAddress = this.updateDeliveryAddress.bind(this);
        this.updateDeliverTo = this.updateDeliverTo.bind(this);
        this.updateCurrentOrder = this.updateCurrentOrder.bind(this);

        this.state = {
            step: 0,
            deliveryMethod: 1,
            note: '',
            deliverTo: 'BillingAddress',
            _loading: true,
            imageErrors: {},
            deliveryMethods:
                [

                ]

        };

    }


    componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' })

        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

        if (this.props.uData && this.props.uData.deliverTo && !this.state.deliverTo) {
            this.setState({
                deliverTo: this.props.uData.deliverTo
            })
        }

        if (prevState.step != this.state.step) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });




        this.props.socketIOClient.on('updateCurrentOrder', (data) => {
            this.props.socketIOClient.emit("fetchCurrentOrder", {});
            this.setState({
                cartChanged: null,
                _loading: null,
                imageErrors: {}
            })


            if (this.state._finishOrder) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
                this.props.socketIOClient.emit('executeOrder', {});
                this.setState({
                    _finishOrder: null,
                })
            }

        })
        this.props.socketIOClient.on('updateUserData', (data) => {
            console.log(data);
            if (data.successful) {
                this.props.setUserData(data.user);
                this.setState({
                    showForm: null,
                    initialValues: null,
                    _loading: null
                })

            }
        });




        this.props.socketIOClient.on('addToCart', (data) => {
            this.props.socketIOClient.emit("fetchCurrentOrder", {});

        })

        this.props.socketIOClient.on('fetchAllProducts', (data) => {
            this.setState({
                freeProducts: data
            })
        })

        this.props.socketIOClient.on('fetchCurrentOrder', (data) => {
            console.log(data);

            if (data && data.addToCartEmitObj && data.addToCartEmitObj._id) {
                if (data.addToCartEmitObj.quantity) {
                    this.props.socketIOClient.emit('addToCart', data.addToCartEmitObj);
                } else {
                    this.removeCartItem(data.addToCartEmitObj)

                }
            }
            if (data && data.addToCartEmitObj2 && data.addToCartEmitObj2._id) {
                if (data.addToCartEmitObj2.quantity) {
                    this.props.socketIOClient.emit('addToCart', data.addToCartEmitObj2);
                } else {
                    this.removeCartItem(data.addToCartEmitObj2)

                }
            }
            if (data && data.removeGratisProducts) {
                this.props.socketIOClient.emit("removeCartItem", { deleteGratisProducts: true });
            }

            if (data.order && ((data.order.subtotal >= 60)) && new Date().getTime() < new Date(2021, 10, 27, 0, 0, 0).getTime()) {
                this.props.socketIOClient.emit('fetchAllProducts', {});

                let check = -1;

                for (let i = 0; i < data.order.products.length; i++) {
                    if (data.order.products[i].package && data.order.products[i].package.freeProduct) {
                        check = i;
                        break;
                    }
                }



                this.setState({
                    freeProduct: check == -1
                })
            } else {

                let check = -1;

                for (let i = 0; i < data.order.products.length; i++) {
                    if (data.order.products[i].package && data.order.products[i].package.freeProduct) {
                        check = i;
                        break;
                    }
                }

                if (check !== -1) {
                    this.props.socketIOClient.emit("removeCartItem", { productId: data.order.products[check]._id });

                }


                this.setState({
                    freeProduct: false
                })

            }

            console.log(data);
            this.setState({
                order: data.order,
                deliveryMethods: data.deliveryMethods
            });

            if (data.order.BillingMethod === 'Per-Nachname' || data.order.BillingMethod === 'Bargeld') {
                this.setState({
                    _loading: null
                })
            }
            /*this.setState({
                cartItems: data
            })*/
        });

        this.props.socketIOClient.on('executeOrder', (data) => {
            this.props.socketIOClient.emit('cartInfo', {});
            window.scrollTo({ top: 0, behavior: 'smooth' })
            if (data.successful) {
                this.props.showInfoMessage('Narudžba je primljena.');
                this.setState({
                    step: 4
                })
            }
        })


        this.props.socketIOClient.on('removeCartItem', (data) => {
            this.props.socketIOClient.emit("cartInfo", {});
            this.props.socketIOClient.emit("fetchCurrentOrder", {});

        });


        this.props.socketIOClient.emit("fetchCurrentOrder", {});

        //this.props.socketIOClient.emit("fetchDeliveryMethods", {});



    }

    updateDeliveryAddress(data) {
        this.setState({
            _loading: true
        })
        console.log(data);
        let obj = {
            'ShippingAddress.Name': data.Name,
            'ShippingAddress.Street': data.Street,
            'ShippingAddress.Zipcode': data.Zipcode,
            'ShippingAddress.City': data.City,
            'ShippingAddress.Country': data.Country,
            'ShippingAddress.EMail': data.EMail,
            'ShippingAddress.Company': data.Company,
            'ShippingAddress.Phone': data.Phone,
            'ShippingAddress.Country': data.Country,
            'ShippingAddress.Region': data.Region,


        }

        this.props.socketIOClient.emit("updateUserData", obj);
    }

    updateDeliverTo() {
        let obj = {
            'deliverTo': this.state.deliverTo
        };

        this.setState({
            _loading: true
        })

        this.props.socketIOClient.emit("updateUserData", obj);

    }

    updateCurrentOrder() {
        this.setState({
            _loading: true
        });

        let obj = {
            products: this.state.order.products
        }

        if (this.state.Coupon) {
            obj.Coupon = this.state.Coupon;
        }

        this.props.socketIOClient.emit("updateCurrentOrder", obj);

    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;

        this.props.socketIOClient.removeAllListeners("updateCurrentOrder");
        this.props.socketIOClient.removeAllListeners("updateUserData");
        this.props.socketIOClient.removeAllListeners("fetchCurrentOrder");
        this.props.socketIOClient.removeAllListeners("executeOrder");
        this.props.socketIOClient.removeAllListeners("removeCartItem");

    }


    login(data) {
        console.log(data);
        this.props.socketIOClient.emit("userLogin", data);
    }


    removeCartItem(item) {
        console.log(true);
        this.props.socketIOClient.emit("removeCartItem", { productId: item._id });
    }

    updateQuantity(idx, val) {
        let order = this.state.order;

        order.products[idx].cartQuantity = val;

        this.setState({
            order: order,

        }, this.updateCurrentOrder);

    }



    render() {
        let cartItems = this.state.order ? this.state.order.products : [];
        console.log(this.state.order);
        return (
            <div className="account-wrap cart-wrap">

                {
                    /* !this.props.uData ? <Redirect to='/login' ></Redirect> : null}*/
                }

                <Container>
                    <Row>
                        <Col lg="12" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <div className="arrow-steps">
                                            <button onClick={() => this.setState({ step: 0 })} className={this.state.step >= 0 ? 'active' : ''}><span>{this.props.translate('1. Korpa')}</span></button>
                                            <button onClick={() => { if (cartItems.length) this.setState({ step: 1 }) }} className={this.state.step >= 1 ? 'active' : ''}><span>{this.props.translate('2. Dostava')}</span></button>
                                            <button onClick={() => { if (this.state.order && this.state.order.ShippingAddress) this.setState({ step: 2 }) }} className={this.state.step >= 2 ? 'active' : ''}><span>{this.props.translate('3. Plaćanje')}</span></button>

                                        </div>
                                    </Col>
                                    {/*this.state.step !== 2 ?
                                        <Col lg="12">
                                            <ul className="cart-progress">

                                                <li className={this.state.step === 0 ? 'active' : 'done'}>
                                                    <div className="icon">
                                                        <Isvg src={cart_icon} />
                                                    </div>
                                                    <p>{this.props.translate('1. Korpa')}</p>
                                                    <p>{this.props.translate('Količina, dostava i plaćanje')}</p>

                                                </li>



                                                <li className={this.state.step === 1 ? 'active' : this.state.step == 2 ? 'done' : ''}>
                                                    <div className="icon">
                                                        <Isvg src={this.state.step == 1 ? cart_done_icon_active : cart_done_icon} />
                                                    </div>
                                                    <p>{this.props.translate('2. Potvrda')}</p>
                                                    <p>{this.props.translate('Potvrda o prijemu narudžbe')}</p>


                                                </li>

                                            </ul>
                                        </Col>
                                        :
                                        null*/
                                    }


                                    <Col lg="12">
                                        {this.state.step === 0 || this.state.step === 1 || this.state.step === 2 ?
                                            <Container className="box-container">

                                                <Row>

                                                    {this.state.step != 2 ?
                                                        <Col lg="12">

                                                            <p>
                                                                {this.props.translate('Proverite svoju narudžbu. Artikle možete obrisati ili promjeniti količinu.')}
                                                            </p>
                                                        </Col>
                                                        :
                                                        null}


                                                    <Col lg="12">
                                                        {this.state.step != 2 ?
                                                            <>
                                                                {this.state.order && this.state.order.remainingToFreeShiping ? <div className='free-shipping-calc'><Isvg src={mark1} /> {this.props.translate('Nedostaje vam')} <span className='price'>{this.state.order.remainingToFreeShiping.toFixed(2).replace('.', ',')} {this.props.currency.code}</span> {this.props.translate('do besplatne dostave!')}  </div> : null}

                                                            </>
                                                            :
                                                            null
                                                        }

                                                        {this.state.step != 2 ?
                                                            <Link to='/category' className='back'>{this.props.translate('Nastavite kupovati')}</Link> : null}
                                                    </Col>
                                                    <Col lg="9" className="checkout-buttons">
                                                    </Col>



                                                    <Col className={this.state.step !== 0 ? 'small-table' : ''} lg={{ size: this.state.step === 0 ? 12 : 6, order: this.state.step === 0 ? 0 : 1 }} xs={{ size: 12, order: 0 }}>
                                                        {
                                                            this.state.step === 2 && this.state.order && this.state.order.ShippingAddress ?
                                                                <div className="address-info">
                                                                    <h3>{this.props.translate('Adresa za dostavu')}</h3>

                                                                    <p>{this.state.order.ShippingAddress.Name}</p>
                                                                    <p>{this.state.order.ShippingAddress.Street}<br /> {this.state.order.ShippingAddress.City}, {this.state.order.ShippingAddress.PostalCode}</p>
                                                                    <p>{this.state.order.ShippingAddress.Country ? this.state.order.ShippingAddress.Country : ''} {this.state.order.ShippingAddress.Region ? this.state.order.ShippingAddress.Region : ''}</p>
                                                                    <p>{this.state.order.ShippingAddress.Phone}</p>
                                                                    <p>{this.state.order.ShippingAddress.EMail}</p>

                                                                </div>
                                                                :
                                                                null
                                                        }


                                                        <Container className="cart-table">
                                                            <Row className="header">
                                                                <Col lg="2" xs="2">{this.props.translate('Količina')}</Col>
                                                                <Col lg="7" xs="7">{this.props.translate('Naziv artikla')}</Col>


                                                                <Col lg={this.state.step === 0 ? "2" : "3"} xs={this.state.step === 0 ? "2" : "3"} className="number">{this.props.translate('Ukupno')}</Col>
                                                                {this.state.step === 0 ? <Col lg="1" xs="1"></Col> : null}

                                                            </Row>

                                                            {
                                                                cartItems.map((item, idx) => {
                                                                    let gratisProduct = false;
                                                                    if (item.package && (item.package.gratisProduct || item.package._gratisProduct || item.package._gratisProduct2)) {
                                                                        gratisProduct = true;
                                                                    }
                                                                    return (
                                                                        <Row className="article-info" key={idx}>
                                                                            {item.package && item.package.freeProduct ?
                                                                                <Col lg="2" xs="2">
                                                                                </Col>

                                                                                :
                                                                                <Col lg="2" xs="2">
                                                                                    {this.state.step === 0 ?
                                                                                        <><input type="text" value={item.cartQuantity} disabled={gratisProduct} onChange={(e) => {
                                                                                            if (!gratisProduct)
                                                                                                this.updateQuantity(idx, e.target.value)
                                                                                        }} /> <label>{this.props.translate('kom.')}</label></>
                                                                                        :
                                                                                        <span className="quantity">x{item.cartQuantity}</span>
                                                                                    }
                                                                                </Col>
                                                                            }
                                                                            <Col lg={"7"} xs={"7"} className="article-name">
                                                                                <img src={Object.translate(item, 'Images', this.props.lang) ? Object.translate(item, 'Images', this.props.lang)[0] : image} />
                                                                                <span>{Object.translate(item, 'package.name', this.props.lang)}
                                                                                    <br />
                                                                                    <span className='cart-ts'>{this.props.translate('Rezervisano do')} <span>{moment.unix(item.ts + 3 * 60 * 60).format('HH:mm')}</span></span>

                                                                                </span>
                                                                            </Col>
                                                                            <Col lg={this.state.step === 0 ? "2" : "3"} xs={this.state.step === 0 ? "2" : "3"} className="number">
                                                                                {item.price ? (parseFloat(item.price) * item.cartQuantity).formatPrice(this.state.order ? this.state.order.currency : this.props.currency) : null}
                                                                            </Col>
                                                                            {this.state.step === 0 ?
                                                                                <Col lg="1" xs="1" className="delete" onClick={() => {
                                                                                    if (item.package && !item.package._gratisProduct && !item.package._gratisProduct2) {
                                                                                        this.removeCartItem(item)
                                                                                    }
                                                                                }}>
                                                                                    <Isvg src={delete_icon} />
                                                                                </Col>
                                                                                :
                                                                                null
                                                                            }
                                                                        </Row>
                                                                    )
                                                                })

                                                            }




                                                            {this.state.order && this.state.order.ShippingAddress && this.state.step !== 0 ?
                                                                <Row className="article-info">
                                                                    <Col lg="6" xs="6" className="d-none d-md-block">
                                                                        {this.props.translate('Cijena dostave')}
                                                                    </Col>
                                                                    <Col lg="6" xs="6" className="number">
                                                                        {parseFloat(this.state.order ? this.state.order.DeliveryMethod : 0).formatPrice(this.state.order ? this.state.order.currency : this.props.currency)}
                                                                    </Col>
                                                                </Row>
                                                                :
                                                                null}


                                                            <Row className="article-info">
                                                                <Col lg={"9"} xs={"9"} className="text-bold">
                                                                    <div>{this.props.translate('UKUPNO')}<br />
                                                                        <span className="vat">{this.props.translate('Uključujući ')} {this.state.order ? (this.state.order.total - ((this.state.order.total * 100) / (100 + this.state.order.vat))).formatPrice(this.state.order ? this.state.order.currency : this.props.currency) : 'NaN'} {this.props.translate('poreza')}</span>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={this.state.step === 0 ? "2" : "3"} xs={this.state.step === 0 ? "2" : "3"} className="number text-bold">
                                                                    {this.state.order && this.state.order.total.formatPrice(this.state.order ? this.state.order.currency : this.props.currency)}
                                                                </Col>
                                                                {this.state.step === 0 ?
                                                                    <Col lg="1" xs="1" className="delete">

                                                                    </Col>
                                                                    :
                                                                    null
                                                                }
                                                            </Row>

                                                            {this.state.freeProduct ?
                                                                <Modal size="lg" isOpen={this.state.freeProduct} className="free-products-modal">
                                                                    <ModalHeader><span>{this.props.translate('Vaša narudžba prelazi 30EUR')}</span><br /> {this.props.translate('IZABERITE GRATIS PROIZVOD')}</ModalHeader>
                                                                    <ModalBody>
                                                                        <Row>
                                                                            {
                                                                                this.state.freeProducts && this.state.freeProducts.length ?
                                                                                    this.state.freeProducts.map((item, idx) => {
                                                                                        return (
                                                                                            <Col lg="4" xs="6">
                                                                                                <div className="free-product">
                                                                                                    <img src={Object.translate(item, 'Images', this.props.lang) ? Object.translate(item, 'Images', this.props.lang)[0] : null} />
                                                                                                    <h6>{Object.translate(item, 'Name', this.props.lang)}</h6>
                                                                                                    <button className="button" onClick={() => {
                                                                                                        this.setState({ freeProduct: null })
                                                                                                        this.props.socketIOClient.emit('addToCart', { _id: item._id, quantity: 1, package: { ...item.package[0], unitPrice: { 'ch': 0, 'de': 0, 'at': 0 }, name: { ...item.package[0].name, de: item.package[0].name.de + ' - GRATIS', en: item.package[0].name.en + ' - FREE' }, price: 0, freeProduct: true } });
                                                                                                    }}>{this.props.translate('IZABERI')}</button>
                                                                                                </div>
                                                                                            </Col>
                                                                                        )
                                                                                    })
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </Row>
                                                                    </ModalBody>
                                                                </Modal>
                                                                :
                                                                null
                                                            }




                                                        </Container>

                                                        {this.state.step === 0 && cartItems.length ?
                                                            <div className="cart-next-button-wrap">
                                                                <button onClick={() => this.setState({ step: 1 })} className="cart-next-button">{this.props.translate('Sledeći korak')}</button>
                                                            </div>
                                                            :
                                                            null
                                                        }

                                                    </Col>



                                                    {
                                                        this.state.step == 1 ?
                                                            <Col lg={{ size: 6, order: 0 }} xs={{ size: 12, order: 1 }} className="form-container">


                                                                <h3>{this.props.translate('Adresa za dostavu')}</h3>


                                                                {this.props.uData ?
                                                                    <OrderForm
                                                                        lang={this.props.lang}
                                                                        initialValues={
                                                                            this.props.uData.deliverTo == 'BillingAddress' && this.props.uData.BillingAddress ?
                                                                                { ...this.props.uData.BillingAddress, Name: this.props.uData.BillingAddress.Name, Country: 'Schweiz' }
                                                                                :
                                                                                this.props.uData.ShippingAddress ?
                                                                                    { ...this.props.uData.ShippingAddress, Name: this.props.uData.ShippingAddress.Name, Country: 'Schweiz' }
                                                                                    :
                                                                                    { Country: 'Schweiz' }
                                                                        }

                                                                        translate={this.props.translate}
                                                                        onSubmit={(data) => {
                                                                            if (data.Country != 'Schweiz') {
                                                                                delete data.Region;
                                                                            }

                                                                            if (this.props.uData) {
                                                                                this.updateDeliveryAddress(data);

                                                                                this.props.socketIOClient.emit('updateCurrentOrder', { ShippingAddress: data });
                                                                                this.setState({ _loading: true });
                                                                            } else {
                                                                                this.setState({ _loading: true });
                                                                                this.props.socketIOClient.emit('updateCurrentOrder', { ShippingAddress: data })
                                                                            }


                                                                            this.setState({
                                                                                step: 2
                                                                            })
                                                                        }}
                                                                    ></OrderForm>

                                                                    :

                                                                    <OrderForm
                                                                        lang={this.props.lang}
                                                                        initialValues={{ Country: 'Schweiz' }}
                                                                        translate={this.props.translate}
                                                                        onSubmit={(data) => {
                                                                            if (data.Country != 'Schweiz') {
                                                                                delete data.Region;
                                                                            }

                                                                            if (this.props.uData) {
                                                                                this.updateDeliveryAddress(data);

                                                                                this.props.socketIOClient.emit('updateCurrentOrder', { ShippingAddress: data });
                                                                                this.setState({ _loading: true });
                                                                            } else {
                                                                                this.setState({ _loading: true });
                                                                                this.props.socketIOClient.emit('updateCurrentOrder', { ShippingAddress: data })
                                                                            }


                                                                            this.setState({
                                                                                step: 2
                                                                            })


                                                                        }}></OrderForm>
                                                                }





                                                            </Col>

                                                            :

                                                            null
                                                    }

                                                    {
                                                        this.state.step == 2 ?
                                                            <Col lg={{ size: 6, order: 0 }} xs={{ size: 12, order: 1 }} className="form-container">
                                                                <h3>{this.props.translate('Izaberite nacin placanja')}</h3>

                                                                <div className="payment-selector">
                                                                    <button onClick={() => this.setState({ paymentMethod: null })} className={this.state.paymentMethod != 'payment-on-account' ? 'active' : ''}>{this.props.translate("Kreditna kartica")}</button>
                                                                    <button onClick={() => this.setState({ paymentMethod: 'payment-on-account' })} className={this.state.paymentMethod == 'payment-on-account' ? 'active' : ''}>{this.props.translate("Uplata na racun")}</button>
                                                                </div>
                                                                {this.state.paymentMethod != 'payment-on-account' ?
                                                                    <Elements stripe={stripePromise}>
                                                                        <InjectedCheckoutForm handleCheckout={(id) => {
                                                                            this.setState({
                                                                                step: 3
                                                                            }, () => {
                                                                                window.scrollTo({ top: 0, behavior: 'smooth' })
                                                                                this.props.socketIOClient.emit('executeOrder', { id });

                                                                            })

                                                                        }} />
                                                                    </Elements>
                                                                    :
                                                                    <div className="payment-account">
                                                                        <h6>CH810021621611075001W</h6>
                                                                        <p>Inhaber: La Mila, Inhaber Milutin Aleksic<br />
                                                                            Konto-Nr.: 216-110750.01W<br />
                                                                            BIC: UBSWCHZH80A<br /></p>
                                                                        <p>{this.props.translate('Čim je iznos prebačen, pokrećemo isporuku.')}</p>
                                                                        <button onClick={
                                                                            () => {
                                                                                this.setState({
                                                                                    step: 3
                                                                                }, () => {
                                                                                    window.scrollTo({ top: 0, behavior: 'smooth' })
                                                                                    this.props.socketIOClient.emit('executeOrder', { paymentOnAccount: true });
                                                                                })

                                                                            }
                                                                        }>{this.props.translate('NARUČI')}</button>
                                                                    </div>
                                                                }

                                                            </Col>



                                                            :
                                                            null
                                                    }

                                                </Row>
                                            </Container>



                                            : null}



                                        {this.state.step == 3 ?
                                            <Container className="box-container order-loading">
                                                <Row>
                                                    <Col lg="12">
                                                        <div className="order-loader">
                                                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                                                        </div>
                                                        <h3 className="order-done-title">{this.props.translate('Obrada narudžbe je u toku.')}</h3>
                                                        <h6 className="order-done-subtitle">{this.props.translate('Molimo pričekajte.')}</h6>
                                                    </Col>

                                                </Row>
                                            </Container>

                                            :
                                            null

                                        }



                                        {this.state.step === 4 ?
                                            <Container className="box-container order-done">
                                                <Row>
                                                    <Col lg="12">
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                            <Player
                                                                autoplay={true}
                                                                loop={true}
                                                                controls={true}
                                                                src="https://assets4.lottiefiles.com/packages/lf20_XUFuoA.json"
                                                                style={{ height: '200px', width: '200px' }}
                                                            ></Player>
                                                        </div>
                                                        <h3 className="order-done-title">{this.props.translate('Vaša narudžba je uspješno izvršena.')}</h3>
                                                        <h6 className="order-done-subtitle">{this.props.translate('Dostava je u roku od dva dana.')}</h6>
                                                    </Col>

                                                </Row>
                                            </Container>

                                            : null
                                        }


                                    </Col>

                                </Row>
                            </Container>

                        </Col>

                    </Row>
                </Container>


                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(CartPage));
